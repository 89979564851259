import React, { useEffect } from "react";
import {
  Popover,
  Fab,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { Toast, consulta } from "../../../funciones/general";

export default function TriviaPopover({ classes, trivia, celularAuth }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState("");
  const btnTrivia = document.getElementById("btnTrivia");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const {
    principal: {
      id: idTrivia,
      nombre,
      valora,
      valorb,
      valorc,
      valord,
      valore,
      valorf,
      valorh,
    },
  } = trivia;

  const guardarTrivia = async () => {
    if (value === valorh) {
      setValue("");
      handleClose();
      Toast.fire({
        icon: "success",
        title: "Felicidades, respuesta correcta.!",
      });
    } else {
      Toast.fire({
        icon: "info",
        title: "Ops, no es la respuesta correcta, intentalo de nuevo.!",
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (btnTrivia) {
      btnTrivia.click();
    }
  }, [btnTrivia]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Fab
        id="btnTrivia"
        elevation={3}
        onClick={handleClick}
        className={classes.btnTrivia}
        color="default"
        aria-label="edit"
      >
        <StarIcon />
      </Fab>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid container className={classes.triviaContainer}>
          <Grid item xs={12} className={classes.triviaHeader}>
            <p>Trivia</p>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{nombre}</FormLabel>
              <RadioGroup name="answer" value={value} onChange={handleChange}>
                {valora && (
                  <FormControlLabel
                    value={"1"}
                    control={<Radio />}
                    label={valora}
                  />
                )}
                {valorb && (
                  <FormControlLabel
                    value={"2"}
                    control={<Radio />}
                    label={valorb}
                  />
                )}
                {valorc && (
                  <FormControlLabel
                    value={"3"}
                    control={<Radio />}
                    label={valorc}
                  />
                )}
                {valord && (
                  <FormControlLabel
                    value={"4"}
                    control={<Radio />}
                    label={valord}
                  />
                )}
                {valore && (
                  <FormControlLabel
                    value={"5"}
                    control={<Radio />}
                    label={valore}
                  />
                )}
                {valorf && (
                  <FormControlLabel
                    value={"6"}
                    control={<Radio />}
                    label={valorf}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={guardarTrivia}
              disabled={!value}
              variant="contained"
              color="secondary"
              fullWidth
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
}
