import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, List, ListItem, ListItemText, Button, Fab, Drawer, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, LinearProgress, CardMedia, CardContent, Typography, Paper, InputBase, Hidden, IconButton, AppBar, Tabs, Tab, Toolbar, FormGroup, Menu, MenuItem, Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/jss/material-dashboard-react/layouts/Template6Style.js";
import FacebookIcon from '@material-ui/icons/Facebook';
import loaderBlack from "../assets/img/loaderBlack.gif";
import imgDefault from "../assets/img/default.png";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TwitterIcon from '@material-ui/icons/Twitter';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EmailIcon from '@material-ui/icons/Email';
import CardHeader from "../components/Card/CardHeader.js";


//Funtions
import { consulta,SetTitle, shareWhatsApp, shareFacebook, getPublicity, showMedia, getCategorys, getContentService, getPermissions, API, Empty, crear_form_data, formulario, NotFound, Toast, validateInputs, getError, mostrarError } from '../funciones/general';


const useStyles = makeStyles(styles);


function ModalPin({ setModalPin, modalPin, accion, pin, setPin, celular, setCelular, errores, callback, callback2, waitPin, pinSend }) {
  const classes = useStyles();
  return (
    <Dialog
      open={modalPin}
      onClose={() => setModalPin(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id="alert-dialog-title">
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>Validar teléfono </h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent className='scroll'>
        <DialogContentText className={classes.textModal}>
          {
            waitPin ? <WaitData />
              :
              <div>
                {
                  accion.accion == 'pin' ?
                    <div>
                      {
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12}>
                            {
                              !pinSend ?
                                <Grid container>
                                  <Grid xs={12} sm={12} md={12}>
                                    <TextField
                                      onChange={({ target: { value } }) => setCelular(value)}
                                      value={celular}
                                      label="Ingresa tu número de teléfono"
                                      error={getError('celular', errores) && true}
                                      helperText={getError('celular', errores)}
                                      fullWidth={true}
                                      required
                                    />
                                  </Grid>
                                </Grid>
                                :
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                      onChange={({ target: { value } }) => setPin(value)}
                                      value={pin}
                                      error={getError('setPin', errores) && true}
                                      helperText={getError('setPin', errores)}
                                      label="Ingresar código"
                                      fullWidth
                                      required
                                    />
                                    <p>Hemos enviado un código de confirmación a tu celular, por favor revísalo.</p>
                                  </Grid>
                                </Grid>
                            }
                          </Grid>
                        </Grid>
                      }
                    </div>
                    : accion.mensaje
                }
              </div>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalPin(false)} color='default' >
          Cerrar
        </Button>
        {
          accion.accion == 'pin' &&
          <Button disabled={waitPin} onClick={() => !pinSend ? callback() : callback2()} className={classes.btn} >
            Aceptar
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}


function Header({ service }) {
  const classes = useStyles();
  return (
    <Grid className={classes.header} style={{ backgroundImage: `url("${API}${service.archivo}")`, }}>
      {service.valorf &&
        <div className={classes.headerContent}>
          <h3 className={classes.headerTitle}>{service.valorf}</h3>
        </div>
      }
    </Grid>
  );
}


function MenuDesktop({ service }) {
  const classes = useStyles();
  return (
    <div>
      <Hidden smUp>
        <div className={classes.rootAppbar}>
          <AppBar position="fixed" className={classes.appbar} elevation={0}>
            <Typography variant="h6" className={classes.titleAppbar}>
              <img src={`${API}${service.archivo3}`} alt="" className={classes.imgAppbar} />
            </Typography>
          </AppBar>
        </div>
      </Hidden>
    </div>
  );
}


function ShowContentCard({ category = '', description = '', date = '', archivo = '', tipo_archivo = '', name = '', id = '' }) {
  const classes = useStyles();

  return (
    <Card className={classes.cardRoot}>
      <p className={classes.cardTitle}>{name}</p>
      <div style={{ height: 190 }}>
        {showMedia(classes.cardMedia, tipo_archivo, archivo)}
      </div>
      <CardContent className={classes.cardContent}>
        <p className={classes.cardTitle}>Compartir:</p>
        <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
          <Grid item md={12} className={classes.containerShare}>
            <FacebookIcon onClick={() => shareFacebook(name, id)} className={classes.btnFacebook} />
            <WhatsAppIcon onClick={() => shareWhatsApp(id)} className={classes.btnWhatsapp} />
            {/* <TwitterIcon onClick={() => window.open(`https://twitter.com/intent/tweet?text=${name}&url=${API + archivo}&via=portal&hashtags=portal,html`)} className={classes.btnTwitter} /> */}
          </Grid>
        </Typography>
        <p className={classes.cardTitleDate}>Publicado : <span className={classes.cardSubTitle}>{date}</span></p>
      </CardContent>
    </Card>
  );
}

function Publicity({ data }) {
  let { archivo, valorc } = data;
  const classes = useStyles();
  return (
    <Card className={classes.cardRootSuscription} onClick={() => valorc && window.open(valorc)}>
      <div className={classes.cardRootSuscriptionBorder}></div>
      <div style={{ height: 190 }}>
        <img
          className={classes.cardMedia}
          src={archivo ? `${archivo}` : imgDefault}
          alt="Imagen Publicidad"
        />
      </div>
      <CardContent className={classes.cardContent}>
        <Button variant="contained" className={classes.cardRootSuscriptionBtn}>Haz Clic Aquí</Button>
      </CardContent>

    </Card>
  );
}


function Suscription({ data }) {
  let { link, nombre } = data;
  const classes = useStyles();
  return (
    <Card className={classes.cardRootSuscription}>
      <div className={classes.cardRootSuscriptionBorder}></div>
      <div style={{ height: 190 }}>
        <p className={classes.cardRootSuscriptionMensaje}>{nombre}</p>
      </div>
      <CardContent className={classes.cardContent}>
        <Button variant="contained" onClick={() => link && window.open(link)} className={classes.cardRootSuscriptionBtn}>Suscríbete ahora</Button>
      </CardContent>
    </Card>
  );
}


function Footer({ setModalTerms, setModalPoliticas, setModalCopyrigth, service, setModalQuestion }) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <img src={`${API}${service.archivo2}`} alt="" className={classes.IconFooter} />
      <Grid container justify="center" alignItems="center" className={classes.footerContentText}>
      {service.valorr == 'ZED' ? '': <Grid onClick={() => setModalPoliticas(true)}><span className={classes.footerText}>Politicas de privacidad</span></Grid>} 
      <Grid onClick={() => setModalTerms(true)}><span className={classes.footerText}>Términos y condiciones</span></Grid>
      {service.valorr == 'ZED' ? '': <Grid onClick={() => setModalQuestion(true)}><span className={classes.footerText}>Preguntas Frecuentes</span></Grid>} 
      {service.valorr == 'ZED' && <Grid ><span className={classes.footerText}>SALIR</span></Grid>}    
      </Grid>
      <div className={classes.copyright}>Copyright© Quicklii {new Date().getFullYear()} -<span onClick={() => setModalCopyrigth(true)} className={classes.footerText}  >VER MÁS</span></div>
    </footer>
  );
}

function ModalText({ setModalTexto, modalText, text, title }) {
  const classes = useStyles();
  return (
    <Dialog
      open={modalText}
      onClose={() => setModalTexto(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>{title}</h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent className='scroll'>
        <DialogContentText className={classes.textModal}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalTexto(false)} className={classes.btn} >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Contact({ callback, setModalContact, modalContact, setNombre, nombre, setCorreo, correo, setCelular, celular, setMensaje, mensaje, errores = [] }) {
  const classes = useStyles();
  const [wait, setWait] = useState(false);
  return (
    <Dialog
      open={modalContact}
      onClose={() => setModalContact(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>Contáctenos</h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent className='scroll'>
        {
          wait ? <WaitData /> :
            <div>
              {
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                      <Grid xs={12} sm={12} md={12}>
                        <TextField
                          onChange={({ target: { value } }) => setNombre(value)}
                          value={nombre}
                          label="Nombre"
                          error={getError('nombre', errores) && true}
                          helperText={getError('nombre', errores)}
                          fullWidth={true}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          onChange={({ target: { value } }) => setCorreo(value)}
                          value={correo}
                          error={getError('correo', errores) && true}
                          helperText={getError('correo', errores)}
                          label="Correo"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          onChange={({ target: { value } }) => setCelular(value)}
                          value={celular}
                          error={getError('celular', errores) && true}
                          helperText={getError('celular', errores)}
                          label="Ingresa tu número de teléfono"
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          onChange={({ target: { value } }) => setMensaje(value)}
                          value={mensaje}
                          label="Mensaje"
                          error={getError('mensaje', errores) && true}
                          helperText={getError('mensaje', errores)}
                          fullWidth={true}
                          multiline
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </div>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalContact(false)} color='default' >
          Cerrar
        </Button>
        <Button disabled={wait} onClick={() => callback(setWait)} className={classes.btn} >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}


function WaitData() {
  const classes = useStyles();
  return (
    <div className={classes.contentWait}>
      <img src={loaderBlack} alt="" className={classes.contentWaitImg} />
    </div>
  );
}

function Wait() {
  return (
    <div >
      {/* <LinearProgress style={{ backgroundColor: '#74462f' }} /> */}
      <div style={{ padding: "200px 0 0 0", width: "100%", textAlign: 'center' }}>
        <img src={loaderBlack} width="200" alt="" />
        <p>Cargando...</p>
      </div>
    </div>
  );
}

function CopyrightMore() {
  return (
    <div>
      <p>Estos servicios son prestados por Quicklii. Si requiere ayuda, soporte técnico o más información comuníquese en Colombia en las líneas de atención al cliente:</p>
      <ul>
        <li>018000180532</li>
        <li>customer.support@quicklii.co</li>
      </ul>
      <p>Todos los derechos reservados {new Date().getFullYear()}.</p>
    </div>
  );
}

export default function Template6({ dataService }) {
  const classes = useStyles();
  const [activeContent, setActiveContent] = useState('');
  const [waitPin, setWaitPin] = useState(false);
  const [pinSend, setPintSend] = useState(false);
  const [modalPin, setModalPin] = useState(false);
  const [pin, setPin] = useState('');
  const [accion, setAccion] = useState({});
  const params = useParams();
  const [wait, setWait] = useState(true);
  const [waitContent, setWaitContent] = useState(true);
  const [error, setError] = useState(false);
  const [errorContent, setErrorContent] = useState(false);
  const [tipoPF, setTipoPF] = useState(0);
  const [pk, setPk] = useState(params.id);
  const [service, setService] = useState({});
  const [dataContent, setDataContent] = useState({});
  const [categorys, setCategorys] = useState([]);
  const [content, setContent] = useState([]);
  const [modalTerms, setModalTerms] = useState(false);
  const [modalPoliticas, setModalPoliticas] = useState(false);
  const [modalDetailContent, setModalDetailContent] = useState(false);
  const [modalCopyrigth, setModalCopyrigth] = useState(false);
  const [modalQuestion, setModalQuestion] = useState(false);
  const [modalContact, setModalContact] = useState(false);
  const [nombre, setNombre] = useState('');
  const [correo, setCorreo] = useState('');
  const [celular, setCelular] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [errores, setErrores] = useState([]);
  const [publicity, setPublicity] = useState([]);

  useEffect(() => {
    getData(pk);
  }, []);

  const getData = async (pk) => {
    setWait(true);
    let { success, data: dataC } = await getCategorys(pk);
    if (success) {
      setService(dataService);
      setCategorys(dataC);
      getDataContent([], dataService);
    } else setError(true);
    setWait(false);
  }

  
  const sendPinPhone = async () => {
    setWaitPin(true);
    consulta(`contenido/pin`, { servicioId: pk, celular }, 'post', (error, estado, resp) => {
      if (estado == 200) {
        setPintSend(true);
        Toast.fire({ icon: 'success', title: resp.titulo });
      } else {
        setPintSend(false);
        Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
      }
      console.log(resp);
    }, false);
    setWaitPin(false);
  }

  const confirmPinPhone = async () => {
    setWaitPin(true);
    consulta(`contenido/confirmar`, { servicioId: pk, celular, codigo: pin }, 'post', (error, estado, resp) => {
      if (estado == 200) {
        Toast.fire({ icon: 'success', title: resp.titulo });
        setModalPin(false);
        setPin('');
        setCelular('');
        setActiveContent(celular);
        getDataContent([{ 'llave': 'activate', 'valor': celular }], service);
      } else {
        Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
      }
    }, false);
    setWaitPin(false);
  }

  
  const getDataContent = async (filters = [], serv = null) => {
    serv = !serv ? service : serv;
    let deactivePublicity = filters.find(element => element.llave == 'searching');
    let activate = filters.find(element => element.llave == 'activate');
    setWaitContent(true);
    let { uId, contentPk } = await getPermissions();
    if (filters.length == 0 && contentPk) filters.push({ 'llave': 'id', 'valor': contentPk });
    filters.push({ 'llave': 'uId', 'valor': uId });
    filters.push({ 'llave': 'servicio', 'valor': pk });
    filters.push({ 'llave': 'ordering', 'valor': '-id' });
    filters.push({ 'llave': 'limit', 'valor': 1 });
    if (!activate && activeContent.length > 0) filters.push({ 'llave': 'activate', 'valor': activeContent });
    let { success: successCon, data: dataCon, category, accion } = await getContentService(filters);
    if (accion && (accion.accion == 'pin' || accion.accion == 'info')) setModalPin(true);
    setAccion(accion);
    let { data: dataPublicity, success: successPublicity } = await getPublicity(pk);
    if (successCon) {
      await addPublicity(dataCon, dataPublicity, successPublicity, deactivePublicity);
      await addSuscription(dataCon, serv, category);
      setTipoPF(category);
    } else setErrorContent(true);
    setWaitContent(false);
  }

  const addNotification = async (data, callback) => {

    let errores = await validateInputs([
      { value: nombre, name: 'nombre', err: { empty: true } },
      { value: correo, name: 'correo', err: { empty: true } },
      { value: celular, name: 'celular', err: { empty: true } },
      { value: mensaje, name: 'mensaje', err: { empty: true } },
    ]);
    if (errores.length == 0) {
      setErrores([]);
      callback(true);
      data = await crear_form_data(data);
      formulario("notificacion/crear", data, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            setNombre('');
            setMensaje('');
            setCorreo('');
            setCelular('');
            setModalContact(false);
            callback(false);
            Toast.fire({ icon: 'success', title: resp.titulo });
          } else {
            Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
            callback(false);
          }
        }, false
      );
    } else {
      setErrores([]);
      setErrores(errores);
    }

  }


  function TransformQuestion({ text }) {
    let list = text.search("!");
    let listF = text.search("¡");
    if (list != -1 && listF != -1) {
      let preguntas = text.split('!');
      return preguntas.map((e, i) => {
        let p = e.split('¡');
        if (p.length == 1) return '';
        return (
          <List key={i}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={``}
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {`${p[0]}`}
                    </Typography>
                    <p className={classes.textModal}>
                      {p[1]}
                    </p>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        );
      })
    } else return text;
  }

  function addPublicity(content, dataPublicity, succesPublicity, deactivePublicity) {
    let index = 0;
    return new Promise((resolve) => {
      if (!deactivePublicity && succesPublicity) {
        dataPublicity.map(({ principal: { archivo, valorc } }) => {
          let data = { archivo, valorc, tipo: 'publicidad' }
          // let ubi = Math.floor((Math.random() * ((content.length) - 0)) + 0);
          content.splice(index, 0, data);
          if (index < dataPublicity.length) index = index + 2;
        })
      }
      setContent(content);
      setPublicity(dataPublicity);
      resolve(true);
    });
  }

  function addSuscription(content, service, category) {
    let indice = 2;
    let name = service && service.nombre ? service.nombre.replace(/ /g, "") : 'portal';
    return new Promise((resolve) => {
      if (category == 1) {
        resolve(content);
      } else {
        let n = Math.ceil(content.length / 5);
        n = n == 0 ? 1 : n;
        for (let index = 0; index < n; index++) {
          let data = { nombre  :  service.valorr == 'ZED' ? 'Accede ahora mismo al contenido.' : 'Suscríbete ahora mismo! Y accede a todo el contenido.', 'link': `/suscripcion/${name}/${service.id}`, tipo: 'suscripcion' };
          content.splice(indice, 0, data);
          indice = indice + 5;
        }
        resolve(content);
      }
    });
  };

  function TransformText({ text }) {
    let list = text.search("!");
    let listF = text.search("¡");
    if (list != -1 && listF != -1) {
      let resp = text.split('¡');
      return resp.map((e, i) => {
        let p = e.split('!');
        if (p.length == 1) return '';
        return (
          <List key={i}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={``}
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"

                    >
                      {`${p[0]}`}
                    </Typography>
                    <p className={classes.textModal}>
                      {p[1]}
                    </p>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        );
      })
    } else return text;
  }


  if (wait) return <Wait />;
  else if ((!wait && error) || (!waitContent && errorContent)) return <NotFound />;
  else return (
    <div className={classes.mainPanel} style={{ backgroundImage: service.archivo5 && `url(${API}${service.archivo5})`, }}>
      {<SetTitle service={service} />}
      <Hidden xsDown>
        <Header service={service} />
      </Hidden>
      <MenuDesktop categorys={categorys} getDataContent={getDataContent} service={service} />
      <div className={classes.mainPanelContent}>
        {
          waitContent ? < WaitData /> :
            <Grid container justify="center" alignItems="center">
              {content.length == 0 && <Empty menssage='No se encontro contenido.' />}
              {
                content.map((e, i) => {
                  if (e.tipo == 'publicidad') {
                    return (

                      <Grid container justify="center" alignItems="center" key={i} item xs={12} sm={6} md={4} lg={3} xl={3} >
                        <Publicity data={e} />
                      </Grid>

                    )
                  } else if (e.tipo == 'suscripcion') {
                    return (
                      <Grid container justify="center" alignItems="center" key={i} item xs={12} sm={6} md={4} lg={3} xl={3} >
                        <Suscription data={e} />
                      </Grid>
                    )
                  } else {
                    return (
                      <Grid container justify="center" alignItems="center" key={i} item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <ShowContentCard id={e.id} name={e.nombre} archivo={e.archivo} tipo_archivo={e.tipo_archivo} category={e.tipo ? e.tipo.nombre : 'Gratis'} date={e.fecha_publicacion} description={e.descripcion} />
                      </Grid>
                    )
                  }
                })
              }
            </Grid>
        }
      </div >
      <Footer service={service} setModalQuestion={setModalQuestion} setModalTerms={setModalTerms} setModalPoliticas={setModalPoliticas} setModalCopyrigth={setModalCopyrigth} />
      <ModalText title='Términos y Condiciones' modalText={modalTerms} setModalTexto={setModalTerms} text={service && service.valore ? <TransformText text={service.valore} /> : ''} />
      <ModalText title='Políticas de privacidad' modalText={modalPoliticas} setModalTexto={setModalPoliticas} text={service && service.valorg ? <TransformText text={service.valorg} /> : ''} />
      <ModalText title='Copyright© Quicklii 2021' modalText={modalCopyrigth} setModalTexto={setModalCopyrigth} text={<CopyrightMore />} />
      <ModalText title='Preguntas Fecuentes' modalText={modalQuestion} setModalTexto={setModalQuestion} text={service && service.valori ? <TransformQuestion text={service.valori} /> : ''} />
      <Contact callback={(setWait) => addNotification({ nombre, mensaje, correo, celular, asunto: `NOTIFICACIÓN - ${service.nombre}` }, setWait)} errores={errores} setModalContact={setModalContact} modalContact={modalContact} setNombre={setNombre} nombre={nombre} setCorreo={setCorreo} correo={correo} setCelular={setCelular} celular={celular} setMensaje={setMensaje} mensaje={mensaje} />
      <Fab elevation={3} onClick={() => setModalContact(true)} className={classes.btnEmail} color="default" aria-label="edit"> <EmailIcon /> </Fab>
      <ModalPin waitPin={waitPin} pinSend={pinSend} modalPin={modalPin} setModalPin={setModalPin} accion={accion} pin={pin} setPin={setPin} celular={celular} setCelular={setCelular} errores={errores} callback={() => sendPinPhone()} callback2={() => confirmPinPhone()} />
    </div >
  );
}
