import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Redirect, BrowserRouter as Brouter } from "react-router-dom";

// core components
import Admin from "./layouts/Admin.js";
import Templates from "./layouts/Templates.js";
import Inscripcion from "./views/Inscripcion.js";
import Products from "./views/Products.js";


import "./assets/css/material-dashboard-react.css?v=1.9.0";
import './App.css';


// let auth = localStorage.getItem('token') ? true : false;


ReactDOM.render(
  <Brouter>
    <Route path="/admin" component={Admin} />
    <Route path="/suscripcion/:nombre/:id" component={Inscripcion} />
    <Route path="/productos/" component={Products} />
    <Route path="/:nombre/:id" component={Templates} />
  </Brouter>,
  document.getElementById("root")
);
