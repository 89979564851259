import React, { useState, useEffect } from "react";
import moment from "moment";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Typography,
  ListItem,
  List,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Checkbox,
  Avatar,
  Tooltip,
  IconButton,
  TextField,
  Table,
  TableBody,
  Grid,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import FlagIcon from "@material-ui/icons/Flag";

import CreateIcon from "@material-ui/icons/Create";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
// core components
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import Dialog from "../components/Dialog/Dialog.js";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardIcon from "../components/Card/CardIcon.js";
import CardFooter from "../components/Card/CardFooter.js";
import ButtonFloating from "../components/CustomButtons/ButtonFloating.js";
import styles from "../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesAdmin from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";

//Funtions
import {
  Empty,
  validateInputs,
  consulta,
  Toast,
  colorDinamic,
  iconoDinamic,
  getError,
} from "../funciones/general.js";

const useStyles = makeStyles(styles);
const useStylesAdmin = makeStyles(stylesAdmin);

function ListService({ servicios, addTriviaToService, removeTriviaToService }) {
  const classes = useStyles();

  const handleToggle = (value, idPermiso) => () => {
    if (!idPermiso) addTriviaToService(value);
    else removeTriviaToService(idPermiso);
  };

  return (
    <List dense className={classes.rootList}>
      {servicios.map(({ id, nombre, permiso }) => {
        const labelId = `checkbox-list-primary-label-${id}`;
        return (
          <>
            <ListItem key={id} button className={classes.itemList}>
              <ListItemAvatar>
                <Avatar
                  style={{
                    background: permiso ? "#009688" : "#b71c1c",
                    color: "white",
                  }}
                >
                  {nombre.charAt(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {nombre}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleToggle(id, permiso)}
                  checked={permiso}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        );
      })}
    </List>
  );
}

function DetailPublicity({ data }) {
  const classes = useStyles();

  let {
    nombre,
    valora,
    valorb,
    valorc,
    valord,
    valore,
    valorf,
    valorg,
    valorh,
    fecha_registro,
    usuario_registro,
    estado,
  } = data;
  let fecha = moment(fecha_registro).format("YYYY-MM-DD, h:mm a");
  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Nombre
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {nombre}
            </Grid>
          </Grid>
        </TableRow>

        <TableRow key={6}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Respuesta 1
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valora}
            </Grid>
          </Grid>
        </TableRow>

        {valorb && (
          <TableRow key={7}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Respuesta 2
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                {valorb}
              </Grid>
            </Grid>
          </TableRow>
        )}

        {valorc && (
          <TableRow key={8}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Respuesta 3
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                {valorc}
              </Grid>
            </Grid>
          </TableRow>
        )}

        {valord && (
          <TableRow key={9}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Respuesta 4
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                {valord}
              </Grid>
            </Grid>
          </TableRow>
        )}

        {valore && (
          <TableRow key={9}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Respuesta 5
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                {valore}
              </Grid>
            </Grid>
          </TableRow>
        )}

        {valorf && (
          <TableRow key={9}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Respuesta 6
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                {valorf}
              </Grid>
            </Grid>
          </TableRow>
        )}

        <TableRow key={10}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Respuesta correcta
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valorh}
            </Grid>
          </Grid>
        </TableRow>

        <TableRow key={3}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Estado Actual
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {estado == 1 ? "Activo" : "Inactivo"}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={4}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Registrado por
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {usuario_registro && usuario_registro.usuario}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={5}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Fecha Registro
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {fecha}
            </Grid>
          </Grid>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function AddTrivia({
  nombre,
  setNombre,
  respuesta1,
  setRespuesta1,
  respuesta2,
  setRespuesta2,
  respuesta3,
  setRespuesta3,
  respuesta4,
  setRespuesta4,
  respuesta5,
  setRespuesta5,
  respuesta6,
  setRespuesta6,
  errores,
  numeroRespuestas,
  setNumeroRespuestas,
  respuestaCorrecta,
  setRespuestaCorrecta,
}) {
  const classes = useStyles();
  console.log("numeroRespuestas", numeroRespuestas);
  return (
    <div>
      <Button
        color="primary"
        disabled={numeroRespuestas === 6}
        onClick={() => setNumeroRespuestas(numeroRespuestas + 1)}
      >
        más respuestas
      </Button>
      {
        <Button
          color="secondary"
          disabled={numeroRespuestas === 1}
          onClick={() => setNumeroRespuestas(numeroRespuestas - 1)}
        >
          menos respuestas
        </Button>
      }
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                onChange={({ target: { value } }) => setNombre(value)}
                value={nombre}
                error={getError("nombre", errores)}
                helperText={getError("nombre", errores)}
                label="Nombre de la Trivia"
                fullWidth={true}
                required
              />
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                onChange={({ target: { value } }) => setRespuesta1(value)}
                value={respuesta1}
                label="Respuesta 1"
                fullWidth={true}
              />
            </GridItem>
          </GridContainer>
          {numeroRespuestas > 1 && (
            <>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    onChange={({ target: { value } }) => setRespuesta2(value)}
                    value={respuesta2}
                    label="Respuesta 2"
                    fullWidth={true}
                  />
                </GridItem>
              </GridContainer>
            </>
          )}

          {numeroRespuestas > 2 && (
            <>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    onChange={({ target: { value } }) => setRespuesta3(value)}
                    value={respuesta3}
                    label="Respuesta 3"
                    fullWidth={true}
                  />
                </GridItem>
              </GridContainer>
            </>
          )}
          {numeroRespuestas > 3 && (
            <>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    onChange={({ target: { value } }) => setRespuesta4(value)}
                    value={respuesta4}
                    label="Respuesta 4"
                    fullWidth={true}
                  />
                </GridItem>
              </GridContainer>
            </>
          )}
          {numeroRespuestas > 4 && (
            <>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    onChange={({ target: { value } }) => setRespuesta5(value)}
                    value={respuesta5}
                    label="Respuesta 5"
                    fullWidth={true}
                  />
                </GridItem>
              </GridContainer>
            </>
          )}
          {numeroRespuestas > 5 && (
            <>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    onChange={({ target: { value } }) => setRespuesta6(value)}
                    value={respuesta6}
                    label="Respuesta 6"
                    fullWidth={true}
                  />
                </GridItem>
              </GridContainer>
            </>
          )}
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  ¿ Seleccione respuesta correcta ?
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={respuestaCorrecta}
                  onChange={({ target: { value } }) =>
                    setRespuestaCorrecta(value)
                  }
                >
                  <MenuItem key={1} value={1}>{`Respuesta 1`}</MenuItem>
                  {numeroRespuestas > 1 && (
                    <MenuItem key={2} value={2}>{`Respuesta 2`}</MenuItem>
                  )}
                  {numeroRespuestas > 2 && (
                    <MenuItem key={3} value={3}>{`Respuesta 3`}</MenuItem>
                  )}
                  {numeroRespuestas > 3 && (
                    <MenuItem key={4} value={4}>{`Respuesta 4`}</MenuItem>
                  )}
                  {numeroRespuestas > 4 && (
                    <MenuItem key={5} value={5}>{`Respuesta 5`}</MenuItem>
                  )}
                  {numeroRespuestas > 5 && (
                    <MenuItem key={6} value={6}>{`Respuesta 6`}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

function ShowData({
  data,
  setDataSele,
  updateValueGenerica,
  setModalAdd,
  setErrores,
  setModalDetail,
  setModalAddService,
  getServices,
  setNombre,
  setRespuesta1,
  setRespuesta2,
  setRespuesta3,
  setRespuesta4,
  setRespuesta5,
  setRespuesta6,
  setNumeroRespuestas,
  setRespuestaCorrecta,
  setTipo,
}) {
  const classes = useStyles();

  const showDataUpdate = (e) => {
    console.log("setNumeroRespuestas", e.valorg);
    console.log("setRespuestaCorrecta", e.valorh);

    setModalAdd(true);
    setDataSele(e);
    setNombre(e.nombre);
    setRespuesta1(e.valora);
    setRespuesta2(e.valorb);
    setRespuesta3(e.valorc);
    setRespuesta4(e.valord);
    setRespuesta5(e.valore);
    setRespuesta6(e.valorf);
    setNumeroRespuestas(e.valorg);
    setRespuestaCorrecta(e.valorh);
    setTipo("update");
    setErrores([]);
  };

  return data.map((e) => {
    const iconoD = iconoDinamic(parseInt(e.valorj));

    return (
      <GridItem xs={12} sm={6} md={3} key={e.nombre}>
        <Card
          className={classes.cardItem}
          onClick={() => {
            setDataSele(e);
            setModalDetail(true);
          }}
        >
          <CardHeader color={e.valori} stats icon>
            <CardIcon color={e.valori}>{iconoD.icon()}</CardIcon>
            <p className={classes.cardCategory}>
              {e.nombre.slice(0, 16).toUpperCase()}
              {e.nombre.length > 16 ? " ..." : ""}
            </p>
            <h4 className={classes.cardTitle}>
              <small></small>
            </h4>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats} style={{ textAlign: "left" }}>
              <FlagIcon />
              {`Estado : ${e.estado == 1 ? "Activo" : "Inactivo"}`}
              <br />
              <DateRange />
              {`Creado el ${moment(e.fecha_registro).format(
                "YYYY-MM-DD, h:mm a"
              )}`}
            </div>
          </CardFooter>
        </Card>
        <div className={classes.stats}>
          {e.estado == 1 ? (
            <Tooltip
              className={classes.statsTolltip}
              style={{ backgroundColor: "#b71c1c" }}
              title="Desactivar"
              onClick={() => updateValueGenerica(e.id, { estado: 0 })}
            >
              <IconButton className={classes.statsIcon}>
                <VisibilityOffIcon fontSize="small" />{" "}
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              className={classes.statsTolltip}
              style={{ backgroundColor: "#009688" }}
              title="Activar"
              onClick={() => updateValueGenerica(e.id, { estado: 1 })}
            >
              <IconButton className={classes.statsIcon}>
                <VisibilityIcon fontSize="small" />{" "}
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            className={classes.statsTolltip}
            style={{ backgroundColor: "#2196f3" }}
            title="Editar"
            onClick={() => showDataUpdate(e)}
          >
            <IconButton className={classes.statsIcon}>
              <CreateIcon fontSize="small" />{" "}
            </IconButton>
          </Tooltip>
          <Tooltip
            className={classes.statsTolltip}
            style={{ backgroundColor: "#673ab7" }}
            title="Servicios"
            onClick={() => {
              setDataSele(e);
              getServices(e.id);
              setModalAddService(true);
            }}
          >
            <IconButton className={classes.statsIcon}>
              <LibraryAddIcon fontSize="small" />{" "}
            </IconButton>
          </Tooltip>
        </div>
      </GridItem>
    );
  });
}

export default function Trivias() {
  let currentUrl = window.location.pathname.split("/")[2];
  const mainPanel = React.createRef();
  const classes = useStylesAdmin();
  const [tipo, setTipo] = useState("add");
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalAddService, setModalAddService] = useState(false);
  const [nombre, setNombre] = useState("");
  const [numeroRespuestas, setNumeroRespuestas] = useState(1);
  const [respuestaCorrecta, setRespuestaCorrecta] = useState(1);
  const [respuesta1, setRespuesta1] = useState("");
  const [respuesta2, setRespuesta2] = useState("");
  const [respuesta3, setRespuesta3] = useState("");
  const [respuesta4, setRespuesta4] = useState("");
  const [respuesta5, setRespuesta5] = useState("");
  const [respuesta6, setRespuesta6] = useState("");

  const [data, setData] = useState([]);
  const [dataSele, setDataSele] = useState({});
  const [errores, setErrores] = useState([]);
  const [dataService, setDataService] = useState([]);

  useEffect(() => {
    getTrivia();
  }, []);

  useEffect(() => {
    if (respuestaCorrecta > numeroRespuestas) {
      setRespuestaCorrecta(numeroRespuestas);
    }
  }, [numeroRespuestas]);

  const addValueGenerica = async (data) => {
    let errores = await validateInputs([
      { value: nombre, name: "nombre", err: { empty: true } },
    ]);
    if (errores.length == 0) {
      setErrores([]);
      consulta("valores/crear", data, "post", (error, estado, resp) => {
        if (estado === 200) {
          setNombre("");
          setNumeroRespuestas(1);
          setRespuestaCorrecta(1);
          setRespuesta1("");
          setRespuesta2("");
          setRespuesta3("");
          setRespuesta4("");
          setRespuesta5("");
          setRespuesta6("");
          getTrivia();
          Toast.fire({ icon: "success", title: "Trivia Guardada" });
        } else {
          let newError = errores;
          setErrores(newError);
          Toast.fire({
            icon: "info",
            title: "Error al guardar la trivia",
          });
        }
      });
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  const updateValueGenerica = async (id, data, tipoUp = "update") => {
    let errores = await validateInputs([
      { value: nombre, name: "nombre", err: { empty: true } },
    ]);
    if (errores.length == 0 || tipoUp != "update_all") {
      setErrores([]);
      consulta(`valores/${id}`, data, "patch", (error, estado, resp) => {
        if (estado === 200) {
          getTrivia();
          setNumeroRespuestas(1);
          setRespuestaCorrecta(1);
          setRespuesta1("");
          setRespuesta2("");
          setRespuesta3("");
          setRespuesta4("");
          setRespuesta5("");
          setRespuesta6("");
          setModalAdd(false);
          Toast.fire({ icon: "success", title: "Trivia actualizada" });
        } else
          Toast.fire({
            icon: "info",
            title: "Error al actualizar la trivia",
          });
      });
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  const getTrivia = (search = "") => {
    consulta(
      `generica/${7}/valores?search=${search}`,
      null,
      null,
      (error, estado, resp) => {
        setData(resp);
      }
    );
  };

  const getServices = (publicity) => {
    consulta(
      `valores/${publicity}/permisos?generica=1`,
      null,
      null,
      (error, estado, resp) => {
        setDataService(resp);
      }
    );
  };

  const addTriviaToService = (principal, secundario) => {
    consulta(
      `permisos/crear`,
      { principal, secundario },
      "post",
      (error, estado, resp) => {
        if (estado === 200) {
          getServices(principal);
          Toast.fire({ icon: "success", title: "Trivia Asignada" });
        } else
          Toast.fire({
            icon: "info",
            title: "Error al asignar la trivia",
          });
      }
    );
  };

  const removeTriviaToService = (id, principal) => {
    consulta(
      `permisos/${id}`,
      { estado: 0 },
      "patch",
      (error, estado, resp) => {
        if (estado === 200) {
          getServices(principal);
          Toast.fire({ icon: "success", title: "Trivia Desasignada" });
        } else
          Toast.fire({
            icon: "info",
            title: "Error al desasignar la trivia",
          });
      }
    );
  };

  return (
    <div className={classes.wrapper}>
      <div ref={mainPanel}>
        <div>
          <Navbar
            text={currentUrl}
            callbackSearch={(data) => {
              getTrivia(data);
            }}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              <div>
                {data.length === 0 && <Empty />}
                <GridContainer>
                  <ShowData
                    data={data}
                    setDataSele={setDataSele}
                    updateValueGenerica={updateValueGenerica}
                    setModalAdd={setModalAdd}
                    setTipo={setTipo}
                    setErrores={setErrores}
                    setModalDetail={setModalDetail}
                    setModalAddService={setModalAddService}
                    getServices={getServices}
                    setNombre={setNombre}
                    setRespuesta1={setRespuesta1}
                    setRespuesta2={setRespuesta2}
                    setRespuesta3={setRespuesta3}
                    setRespuesta4={setRespuesta4}
                    setRespuesta5={setRespuesta5}
                    setRespuesta6={setRespuesta6}
                    setNumeroRespuestas={setNumeroRespuestas}
                    setRespuestaCorrecta={setRespuestaCorrecta}
                  />
                </GridContainer>
                <ButtonFloating callback={() => setModalAdd(true)} />
                <Dialog
                  open={modalAdd}
                  setOpen={(modal) => {
                    if (tipo == "update") {
                      setNombre("");
                      setRespuesta1("");
                      setRespuesta2("");
                      setRespuesta3("");
                      setRespuesta4("");
                      setRespuesta5("");
                      setRespuesta6("");
                    }
                    setTipo("add");
                    setErrores([]);
                    setModalAdd(modal);
                  }}
                  title={`${tipo == "add" ? "Nueva" : "Modificar"} Trivia`}
                  subTitle="Complete los siguientes campos"
                  callback={() => {
                    const dataAdd = {
                      generica: 7,
                      nombre,
                      valora: respuesta1,
                      valorb: respuesta2,
                      valorc: respuesta3,
                      valord: respuesta4,
                      valore: respuesta5,
                      valorf: respuesta6,
                      valorg: numeroRespuestas,
                      valorh: respuestaCorrecta,
                      valori: colorDinamic().color,
                      valorj: parseInt(Math.random() * (10 - 0) + 0),
                    };
                    if (tipo == "add") addValueGenerica(dataAdd);
                    else
                      updateValueGenerica(dataSele.id, dataAdd, "update_all");
                    return false;
                  }}
                >
                  <AddTrivia
                    errores={errores}
                    nombre={nombre}
                    setNombre={setNombre}
                    respuesta1={respuesta1}
                    setRespuesta1={setRespuesta1}
                    respuesta2={respuesta2}
                    setRespuesta2={setRespuesta2}
                    respuesta3={respuesta3}
                    setRespuesta3={setRespuesta3}
                    respuesta4={respuesta4}
                    setRespuesta4={setRespuesta4}
                    respuesta5={respuesta5}
                    setRespuesta5={setRespuesta5}
                    respuesta6={respuesta6}
                    setRespuesta6={setRespuesta6}
                    numeroRespuestas={numeroRespuestas}
                    setNumeroRespuestas={setNumeroRespuestas}
                    respuestaCorrecta={respuestaCorrecta}
                    setRespuestaCorrecta={setRespuestaCorrecta}
                  />
                </Dialog>

                <Dialog
                  nameBtn1=""
                  nameBtn2="CERRAR"
                  open={modalDetail}
                  setOpen={setModalDetail}
                  title={`Detalle Publicidad`}
                  subTitle={""}
                >
                  {dataSele ? <DetailPublicity data={dataSele} /> : <p></p>}
                </Dialog>

                <Dialog
                  nameBtn1=""
                  nameBtn2="CERRAR"
                  open={modalAddService}
                  setOpen={setModalAddService}
                  title={`Asignar Servicio`}
                  subTitle={dataSele && dataSele.nombre}
                >
                  <ListService
                    servicios={dataService}
                    addTriviaToService={(service) =>
                      addTriviaToService(dataSele.id, service)
                    }
                    removeTriviaToService={(id) =>
                      removeTriviaToService(id, dataSele.id)
                    }
                  />
                </Dialog>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
