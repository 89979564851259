import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  List,
  Box,
  Stepper,
  ListItem,
  Step,
  ListItemText,
  Button,
  StepLabel,
  StepContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
  Paper,
  InputBase,
  Hidden,
  IconButton,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo_q from "../assets/img/logo_q.png";
import styles from "../assets/jss/material-dashboard-react/layouts/ProductsStyle.js";
import loaderYellow from "../assets/img/loaderYellow.gif";
import SearchIcon from "@material-ui/icons/Search";
import CardHeader from "../components/Card/CardHeader.js";
//Funtions
import { getServiceSimple, NotFound, Toast, API } from "../funciones/general";

const useStyles = makeStyles(styles);

function Wait() {
  return (
    <div>
      <LinearProgress color="secondary" />
      <div
        style={{ padding: "200px 0 0 0", width: "100%", textAlign: "center" }}
      >
        <img src={loaderYellow} width="200" alt="" />
        <p>Cargando...</p>
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function ModalText({ setModalTexto, modalText, text, title }) {
  const classes = useStyles();
  return (
    <Dialog
      open={modalText}
      onClose={() => setModalTexto(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>{title}</h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent className="scroll">
        <DialogContentText className={classes.textModal}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalTexto(false)} className={classes.btn}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CopyrightMore() {
  return (
    <div>
      <p>
        Estos servicios son prestados por Quicklii. Si requiere ayuda, soporte
        técnico o más información comuníquese en Colombia en las líneas de
        atención al cliente:
      </p>
      <ul>
        <li>018000180532</li>
        <li>customer.support@quicklii.co</li>
      </ul>
      <p>Todos los derechos reservados {new Date().getFullYear()}.</p>
    </div>
  );
}

function getSteps() {
  return [
    "Términos y Condiciones",
    "Politicas de Privacidad",
    "Preguntas Frecuentes",
    "¿Como suscribirme?",
  ];
}

export default function Products() {
  const classes = useStyles();
  const [wait, setWait] = useState(true);
  const [waitContent] = useState(true);
  const [error] = useState(false);
  const [searching, setsearching] = useState("");
  const [errorContent, setErrorContent] = useState(false);
  const [content, setContent] = useState([]);
  const [value, setValue] = React.useState(0);
  const [modalCopyrigth, setModalCopyrigth] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    getDataContent();
  };

  const getDataContent = async (search = "") => {
    setWait(true);
    let { success, data } = await getServiceSimple(1, search);
    if (success) {
      if (data.length != 0) setContent(data);
      else Toast.fire({ icon: "info", title: "No se encontraron servicios!" });
    } else setErrorContent(true);
    setWait(false);
  };
  function getStepContent(step, valore, valorg, valori, valoru) {
    switch (step) {
      case 0:
        return valore ? <TransformText text={valore} /> : "";
      case 1:
        return valorg ? <TransformText text={valorg} /> : "";
      case 2:
        return valori ? <TransformText text={valori} /> : "";
      case 3:
        return valoru ? <TransformText text={valoru} /> : "";
      default:
        return "Unknown step";
    }
  }
  function TransformText({ text }) {
    let list = text.search("!");
    let listF = text.search("¡");
    if (list != -1 && listF != -1) {
      let resp = text.split("¡");
      return resp.map((e, i) => {
        let p = e.split("!");
        if (p.length == 1) return "";
        return (
          <List key={i}>
            <ListItem alignItems="flex-start">
              <ListItemText
                secondary={
                  <React.Fragment>
                    <p className={classes.text}>
                      {" "}
                      <b>{p[0]}</b>{" "}
                    </p>
                    <p className={classes.text}>
                      <TransformTextToLink text={p[1]} />
                    </p>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        );
      });
    } else return <p className={classes.text}>{text}</p>;
  }
  function TransformTextToLink({ text }) {
    let list = text.search("#link");
    let listF = text.search("link#");
    if (list != -1 && listF != -1) {
      let resp = text.split("#link");
      return resp.map((e, i) => {
        let p = e.split("link#");

        if (p.length == 1) return p[0];
        let link = p[0].split("==");
        if (link.length < 2) return e;
        return (
          <>
            <a className={classes.link} href={link[1]} target="_blank">
              {link[0]}
            </a>
            {p[1]}
          </>
        );
      });
    } else return text;
  }

  const isCheck = (category) => ({
    background: category && `#d32f2f`,
    color: category && `white`,
    fontWeight: category && `bold`,
  });

  if (wait) return <Wait />;
  else if ((!wait && error) || (!waitContent && errorContent))
    return <NotFound />;
  // else if (content.length == 0) return <Empty />;
  else
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <AppBar
            position="fixed"
            color="default"
            className={classes.appBar}
            elevation={0}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Paper className={classes.rootSearch} elevation={0}>
                  <img src={logo_q} alt="" width="30" height="30" />

                  <InputBase
                    value={searching}
                    onChange={({ target: { value } }) => setsearching(value)}
                    onKeyDown={(e) =>
                      e.key === "Enter" && getDataContent(searching)
                    }
                    className={classes.input}
                    placeholder="Buscar..."
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                  <IconButton
                    onClick={() => getDataContent(searching)}
                    className={classes.iconButton}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {content.map(({ nombre }, i) => (
                    <Tab
                      key={i}
                      style={isCheck(value == i)}
                      label={<span>{nombre}</span>}
                      {...a11yProps(i)}
                    />
                  ))}
                </Tabs>
              </Grid>
            </Grid>
          </AppBar>
          {content.map(
            ({ nombre, archivo7, valore, valorg, valori, valoru }, i) => (
              <TabPanel value={value} index={i} key={i}>
                <Grid container id="products">
                  <Hidden smDown>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <img
                        src={API + archivo7}
                        alt="Logo"
                        className={classes.logo}
                      />
                    </Grid>
                  </Hidden>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    className={classes.contentText}
                  >
                    <Hidden mdUp>
                      <img
                        src={API + archivo7}
                        alt="Logo"
                        className={classes.logo_alt}
                      />
                    </Hidden>
                    <div className={classes.rootStepper}>
                      <Stepper
                        activeStep={activeStep}
                        orientation="vertical"
                        className={classes.rootStepper}
                      >
                        {steps.map((label, index) => (
                          <Step key={label}>
                            <StepLabel>
                              <h4
                                onClick={() => setActiveStep(index)}
                                className={classes.titulo}
                              >
                                {label}
                              </h4>
                            </StepLabel>
                            <StepContent>
                              <Typography>
                                {getStepContent(
                                  index,
                                  valore,
                                  valorg,
                                  valori,
                                  valoru
                                )}
                              </Typography>
                              <div className={classes.actionsContainerStepper}>
                                <div>
                                  <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.buttonStepper}
                                  >
                                    Regresar
                                  </Button>
                                  <Button
                                    color="default"
                                    onClick={handleNext}
                                    className={classes.buttonStepperColor}
                                  >
                                    {activeStep === steps.length - 1
                                      ? "Finalizar"
                                      : steps[index + 1]}
                                  </Button>
                                </div>
                              </div>
                            </StepContent>
                          </Step>
                        ))}
                      </Stepper>
                      {activeStep === steps.length && (
                        <Paper
                          square
                          elevation={0}
                          className={classes.resetContainerStepper}
                        >
                          <Button
                            color="default"
                            variant="contained"
                            onClick={handleReset}
                            className={classes.buttonStepperColor}
                          >
                            Ver de nuevo
                          </Button>
                        </Paper>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
            )
          )}
          <ModalText
            title="Copyright© Quicklii 2021"
            modalText={modalCopyrigth}
            setModalTexto={setModalCopyrigth}
            text={<CopyrightMore />}
          />
        </Grid>
        <AppBar
          position="fixed"
          color="default"
          className={classes.appBarFooter}
          elevation={0}
        >
          <Button
            onClick={() =>
              window.open(
                "https://www.tramitescrcom.gov.co/tramites/comun/guia.xhtml?tit=&subt=&tram=ebhrEbS9qKn04bkBEBApXQ==&pr1=3578LkxQ3TO3y3t5FY9/BQ==&onA=S0etv8CCdHqPI8wYF9h5dtdTbijZh1Ii32LohKmsEd6aN1b0ia4DPDtKgFkbFjDn"
              )
            }
            className={classes.textFooter}
          >
            Registro de Números Excluidos (RNE){" "}
          </Button>
          <div className={classes.copyright}>
            Copyright© Quicklii {new Date().getFullYear()} -
            <span
              onClick={() => setModalCopyrigth(true)}
              className={classes.footerText}
            >
              VER MÁS
            </span>
          </div>
        </AppBar>
      </Grid>
    );
}
