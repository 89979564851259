
import { makeStyles } from '@material-ui/core/styles';

const footerStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  navigation: {
    fontWeight: 'bold',
    fontSize: '11px',
  },
}));

export default footerStyle;
