import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, List, ListItem, ListItemText, Button, ListItemAvatar, Avatar, Fab, Drawer, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, LinearProgress, CardMedia, CardContent, Typography, Paper, InputBase, Hidden, IconButton, AppBar, Tabs, Tab, Toolbar, FormGroup, Menu, MenuItem, Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import styles from "../assets/jss/material-dashboard-react/layouts/Template21Style.js";
import loaderBlack from "../assets/img/loaderBlack.gif";
import imgDefault from "../assets/img/default.png";
import userdefault from "../assets/img/userdefault.png";
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EmailIcon from '@material-ui/icons/Email';
import CardHeader from "../components/Card/CardHeader.js";
import tiktok from "../assets/img/tiktok.png";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//Funtions
import { consulta, SetTitle, shareWhatsApp, shareFacebook, getPublicity, showMedia, getCategorys, getSubCategorys, getContentService, getPermissions, API, Empty, crear_form_data, formulario, NotFound, Toast, validateInputs, getError, mostrarError } from '../funciones/general';


const useStyles = makeStyles(styles);


function ModalPin({ setModalPin, modalPin, accion, pin, setPin, celular, setCelular, errores, callback, callback2, waitPin, pinSend }) {
  const classes = useStyles();
  return (
    <Dialog
      open={modalPin}
      onClose={() => setModalPin(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id="alert-dialog-title">
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>Validar teléfono </h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent className='scroll'>
        <DialogContentText className={classes.textModal}>
          {
            waitPin ? <WaitData />
              :
              <div>
                {
                  accion.accion == 'pin' ?
                    <div>
                      {
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12}>
                            {
                              !pinSend ?
                                <Grid container>
                                  <Grid xs={12} sm={12} md={12}>
                                    <TextField
                                      onChange={({ target: { value } }) => setCelular(value)}
                                      value={celular}
                                      label="Ingresa tu número de teléfono"
                                      error={getError('celular', errores) && true}
                                      helperText={getError('celular', errores)}
                                      fullWidth={true}
                                      required
                                    />
                                  </Grid>
                                </Grid>
                                :
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                      onChange={({ target: { value } }) => setPin(value)}
                                      value={pin}
                                      error={getError('setPin', errores) && true}
                                      helperText={getError('setPin', errores)}
                                      label="Ingresar código"
                                      fullWidth
                                      required
                                    />
                                    <p>Hemos enviado un código de confirmación a tu celular, por favor revísalo.</p>
                                  </Grid>
                                </Grid>
                            }
                          </Grid>
                        </Grid>
                      }
                    </div>
                    : accion.mensaje
                }
              </div>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalPin(false)} color='default' >
          Cerrar
        </Button>
        {
          accion.accion == 'pin' &&
          <Button disabled={waitPin} onClick={() => !pinSend ? callback() : callback2()} className={classes.btn} >
            Aceptar
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

function Header({ service }) {
  const classes = useStyles();
  return (
    <Grid className={classes.header} style={{ backgroundImage: `url("${API}${service.archivo}")`, }}>
      {service.valorf &&
        <div className={classes.headerContent}>
          <h3 className={classes.headerTitle}>{service.valorf}</h3>
        </div>
      }
    </Grid>
  );
}
function HeaderApp({ service }) {
  const classes = useStyles();
  return (
    <Grid className={classes.headerApp} style={{ backgroundImage: `url("${API}${service.archivo3}")`, }}>
    </Grid>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function Modalsearch({ category, ordering, getData, setModalSearching, Modalsearching, subCategory }) {
  const classes = useStyles();
  const [searching, setsearching] = React.useState('');
  return (
    <Dialog
      open={Modalsearching}
      onClose={() => setModalSearching(false)}
    >
      <DialogTitle>
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>Buscar Contenido</h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TextField
            value={searching}
            onChange={({ target: { value } }) => setsearching(value)}
            onKeyDown={(e) => (e.key === 'Enter') && getData({ category, ordering, searching, subCategory })}
            label="Buscar"
            fullWidth
            color='primary'
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalSearching(false)} >  Cerrar </Button>
        <Button onClick={() => getData({ category, ordering, searching, subCategory })} className={classes.btn} >  Aceptar </Button>
      </DialogActions>
    </Dialog>
  );
}


function MenuDesktop({ categorys, getDataContent, service, filterCategory, subCategorys }) {
  const classes = useStyles();

  const [category, setCategory] = React.useState(0);
  const [subCategory, setSubCategory] = React.useState(0);
  const [ordering, setordering] = React.useState('-fecha_publicacion');
  const [searching, setsearching] = React.useState('');
  const [Modalsearching, setModalSearching] = React.useState(false);

  const [drawerMenu, setDrawerMenu] = React.useState(false);;


  const getData = ({ searching = '', category = 0, ordering = '', subCategory = '' }) => {
    let filters = [];
    if (category > 0) filters.push({ 'llave': 'tipo', 'valor': categorys[category - 1][1] });
    if (subCategory > 0) filters.push({ 'llave': 'sub_tipo', 'valor': subCategorys[subCategory - 1].secundario.id });
    if (ordering) filters.push({ 'llave': 'ordering', 'valor': ordering });
    if (searching) filters.push({ 'llave': 'searching', 'valor': searching });
    getDataContent(filters);
    return false;
  }

  const isCheck = (category) => (
    {
      background: category && `white`,
      color: category && `#00A3EE`,
      fontWeight: category && `bold`,
    }
  )

  const isCheckSub = (category) => (
    {
      color: category && `white`,
      borderBottom: category && `1px solid #8D8D8D`,
      borderRadius: category && "2.5px",
    }
  )

  const isCheckSubApp = (category) => (
    {
      background: category && `white`,
      color: category && `black`,
      fontWeight: category && `bold`,
      borderBottom: category && `4px solid #8D8D8D`,
      borderRadius: category && "2.5px",
    }
  )

  const isCheckApp = (category) => (
    {
      // background: category && `white`,
      color: category && `#00A3EE`,
      fontWeight: category && `bold`,
    }
  )

  const handleChange = (event, newValue) => {
    setCategory(newValue);
    getData({ category: newValue, ordering, searching, subCategory: 0 });
    setSubCategory(0);
  };

  const handleChangeSub = (event, newValue) => {
    setSubCategory(newValue);
    getData({ category, ordering, searching, subCategory: newValue });
  };


  return (
    <div>
      <Hidden smUp>
        <div className={classes.rootAppbar}>
          <AppBar position="fixed" className={classes.appbar} elevation={0}>
            <Toolbar>
              <IconButton onClick={() => setDrawerMenu(true)} edge="start" className={classes.menuButtonAppbar} color="inherit" aria-label="menu">
                <MenuIcon className={classes.iconAppbar} />
              </IconButton>
              <Typography variant="h6" className={classes.titleAppbar}>
                <img src={`${API}${service.archivo4}`} alt="" className={classes.imgAppbarApp} />
              </Typography>
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setModalSearching(true)}
                  color="inherit"
                >
                  <SearchIcon className={classes.iconAppbar} />
                </IconButton>
              </div>

            </Toolbar>
          </AppBar>
        </div>
        <Drawer className='menuTemp1' anchor='left' open={drawerMenu} onClose={() => setDrawerMenu(false)} >
          <AppBar className={classes.DrawerMenuAppBar} position="initial" elevation={0}>
            <Toolbar >
              <IconButton onClick={() => setDrawerMenu(false)} edge="start" className={classes.menuButtonAppbar} color="inherit" aria-label="menu">
                <CloseIcon className={classes.iconAppbar} />
              </IconButton>
              <img src={`${API}${service.archivo4}`} alt="" className={classes.IconDrawerMenu} />
            </Toolbar>
          </AppBar>
          <Grid container justify="center" alignItems="center" className={classes.DrawerMenuHeader} >
            <List className={classes.root}>
              <ListItem className={classes.DrawerListItem}>
                <ListItemAvatar className={classes.DrawerListAvatar}>
                  <Avatar src={userdefault} />
                </ListItemAvatar>
                <ListItemText primary="Bienvenido"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.DrawerListItem}
                        color="textPrimary"
                      >
                        <span className={classes.DrawerListSubItem}> Puntos : 1</span>
                        <br />
                        <span className={classes.DrawerListSubItem}> Puesto : 1</span>
                      </Typography>

                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <span key={0} className={classes.chipAppTodo} style={isCheckApp(category == 0)} onClick={() => handleChange(null, 0)}><span >INICIO</span></span>

          {
            categorys.map((e, i) => {
              return (
                <Accordion elevation={0} style={isCheckApp(category == i + 1)} expanded={category == i + 1} style={{ maxWidth: 255 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    elevation={0}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.chipApp}
                    onClick={() => handleChange(null, i + 1)}
                  >
                    <Typography style={isCheckApp(category == i + 1)}>{e[0]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={0} className={classes.chipSubApp} style={isCheckSubApp(subCategory == 0)} onClick={() => handleChangeSub(null, 0)}>Todo</Grid>
                      {
                        subCategorys.map(({ secundario }, j) => {
                          return (
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={j + 1} className={classes.chipSubApp} style={isCheckSubApp(subCategory == j + 1)} onClick={() => handleChangeSub(null, j + 1)}>{secundario.nombre}</Grid>
                          );
                        })
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                // <span onClick={() => handleChange(null, i + 1)} key={i + 1} className={classes.chipApp}  ><span>{e[0]}</span></span>
              );
            })
          }
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Grid container className={classes.menu}>
          <Grid item className={classes.chips} xs={12} sm={12} md={12} lg={12} xl={12}>
            <AppBar position="static" color="default" elevation={0} className={classes.appbar}>
              <Grid container >
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={classes.chipLogo}>
                  <img src={`${API}${service.archivo4}`} alt="" className={classes.imgAppbar} />
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >
                  <Tabs
                    value={category}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab key={0} className={classes.chip} style={isCheck(category == 0)} label='Inicio' {...a11yProps(0)} />
                    {
                      categorys.map((e, i) => {
                        return (
                          <Tab key={i + 1} className={classes.chip} style={isCheck(category == i + 1)} label={e[0]} {...a11yProps(i + 1)} />
                        );
                      })
                    }
                  </Tabs>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ textAlign: 'center' }}>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => setModalSearching(true)}
                    color="inherit"
                  >
                    <SearchIcon className={classes.iconAppbar} />
                  </IconButton>
                </Grid>
              </Grid>
            </AppBar>
          </Grid>

        </Grid>
        {
          (filterCategory && subCategorys.length > 0) &&
          <AppBar position="static" color="default" elevation={0} className={classes.appbarSub}>
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Tabs
                  value={subCategory}
                  onChange={handleChangeSub}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab key={0} className={classes.chipSub} style={isCheckSub(subCategory == 0)} label='Todo' {...a11yProps(0)} />

                  {
                    subCategorys.map(({ secundario }, i) => {
                      return (
                        <Tab key={i + 1} className={classes.chipSub} style={isCheckSub(subCategory == i + 1)} label={secundario.nombre} {...a11yProps(i + 1)} />
                      );
                    })
                  }
                </Tabs>
              </Grid>
            </Grid>
          </AppBar>
        }
      </Hidden>
      <Modalsearch category={category} subCategory={subCategory} ordering={ordering} getData={getData} setModalSearching={setModalSearching} Modalsearching={Modalsearching} />
    </div >
  );
}

function ShowContentCard({ subcategory, category = '', description = '', date = '', image = '', nombre = '', service }) {
  const classes = useStyles();
  return (
    <Card className={classes.cardRoot}>

      <div style={{ height: 190 }}>
        <img
          className={classes.cardMedia}
          src={image ? `${API}${image}` : imgDefault}
          alt="Imagen Contentido"
        />
        <Grid className={classes.AppBarContentCard} container >
          <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>{category}</Grid>
          <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >| {subcategory}</Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}><img src={`${API}${service.archivo4}`} alt="" className={classes.IconContentCard} /></Grid>
        </Grid>
      </div>
      <CardContent className={classes.cardContent}>
        <p className={classes.cardTitleDate}><span className={classes.cardSubTitle}>{date}</span></p>
        <p className={classes.cardTitle}>
          {nombre.slice(0, 49)}
          {nombre.length > 49 ? ' ...' : ''}
        </p>
        <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
          {description.slice(0, 110)}
          {description.length > 110 ? ' ...' : '.'}
        </Typography>
      </CardContent>
    </Card>
  );
}

function Publicity({ data }) {
  let { archivo, valorc } = data;
  const classes = useStyles();
  return (
    <Card className={classes.cardRootSuscription} onClick={() => valorc && window.open(valorc)}>
      <div className={classes.cardRootSuscriptionBorder}></div>
      <div style={{ height: 190 }}>
        <img
          className={classes.cardMedia}
          src={archivo ? `${archivo}` : imgDefault}
          alt="Imagen Publicidad"
        />
      </div>
      <CardContent className={classes.cardContent}>
        <Button variant="contained" className={classes.cardRootSuscriptionBtn}>Haz Clic Aquí</Button>
      </CardContent>

    </Card>
  );
}


function Suscription({ data }) {
  let { link, nombre } = data;
  const classes = useStyles();
  return (
    <Card className={classes.cardRootSuscription}>
      <div className={classes.cardRootSuscriptionBorder}></div>
      <div style={{ height: 190 }}>
        <p className={classes.cardRootSuscriptionMensaje}>{nombre}</p>
      </div>
      <CardContent className={classes.cardContent}>
        <Button variant="contained" onClick={() => link && window.open(link)} className={classes.cardRootSuscriptionBtn}>Suscríbete ahora</Button>
      </CardContent>
    </Card>
  );
}

function Footer({ setModalTerms, setModalPoliticas, setModalCopyrigth, service, setModalQuestion }) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Grid className={classes.footerImg}>
        <Grid container container justify="center" alignItems="center">
          <Hidden xsDown>
            <Grid xs={4} sm={4} md={4}>
              <Grid container container justify="center" alignItems="center" >
                <Grid md={12} > <Button onClick={() => window.open("https://twitter.com/nextfivecol")} className={classes.SocialBtn} startIcon={<TwitterIcon className={classes.SocialIcons} />}> @nextfivecol</Button>  </Grid>
                <Grid md={12}><Button onClick={() => window.open("https://www.tiktok.com/@nextfivecolombia?lang=es")} className={classes.SocialBtn} startIcon={<img className={classes.SocialIcons} src={tiktok} height="17" alt="Sin datos" />}> Nextfivecolombia</Button></Grid>
                <Grid md={12}><Button onClick={() => window.open("https://www.youtube.com/c/NextFiveColombia5")} className={classes.SocialBtn} startIcon={<YouTubeIcon className={classes.SocialIcons} />}> Next Five Colombia</Button></Grid>
              </Grid>
            </Grid>
            <Grid xs={4} sm={4} md={4}>
              <img src={`${API}${service.archivo2}`} alt="" className={classes.IconFooter} />
            </Grid>
            <Grid xs={4} sm={4} md={4}>
              <Grid container container justify="center" alignItems="center">
                <Grid md={12}> <Button onClick={() => window.open("https://www.facebook.com/nextfivecol")} className={classes.SocialBtn} startIcon={<FacebookIcon className={classes.SocialIcons} />}> Next Five Colombia</Button>  </Grid>
                <Grid md={12}><Button onClick={() => window.open("https://www.instagram.com/nextfivecolombia/")} className={classes.SocialBtn} startIcon={<InstagramIcon className={classes.SocialIcons} />}> @nextfivecolombia</Button></Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <img src={`${API}${service.archivo2}`} alt="" className={classes.IconFooterApp} />
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={6} xl={6} >
              <Grid container container justify="center" alignItems="center" >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > <Button onClick={() => window.open("https://twitter.com/nextfivecol")} className={classes.SocialBtnApp} startIcon={<TwitterIcon className={classes.SocialIconsApp} />}> @nextfivecol</Button>  </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><Button onClick={() => window.open("https://www.tiktok.com/@nextfivecolombia?lang=es")} className={classes.SocialBtnApp} startIcon={<img className={classes.SocialIconsApp} src={tiktok} height="17" alt="Sin datos" />}> Nextfivecolombia</Button></Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><Button onClick={() => window.open("https://www.youtube.com/c/NextFiveColombia5")} className={classes.SocialBtnApp} startIcon={<YouTubeIcon className={classes.SocialIconsApp} />}> Next Five Colombia</Button></Grid>
              </Grid>
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container container justify="center" alignItems="center" >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> <Button onClick={() => window.open("https://www.facebook.com/nextfivecol")} className={classes.SocialBtnApp} startIcon={<FacebookIcon className={classes.SocialIconsApp} />}> Next Five Colombia</Button>  </Grid>
                <Grid itemxs={12} sm={12} md={12} lg={12} xl={12}><Button onClick={() => window.open("https://www.instagram.com/nextfivecolombia/")} className={classes.SocialBtnApp} startIcon={<InstagramIcon className={classes.SocialIconsApp} />}> @nextfivecolombia</Button></Grid>
              </Grid>
            </Grid>
          </Hidden>

        </Grid>
      </Grid>
      <Grid>
      <Grid container justify="center" alignItems="center" className={classes.footerContentText}>
      {service.valorr == 'ZED' ? '': <Grid onClick={() => setModalPoliticas(true)}><span className={classes.footerText}>Politicas de privacidad</span></Grid>} 
      <Grid onClick={() => setModalTerms(true)}><span className={classes.footerText}>Términos y condiciones</span></Grid>
      {service.valorr == 'ZED' ? '': <Grid onClick={() => setModalQuestion(true)}><span className={classes.footerText}>Preguntas Frecuentes</span></Grid>} 
      {service.valorr == 'ZED' && <Grid ><span className={classes.footerText}>SALIR</span></Grid>}    
      </Grid>
      </Grid>
    </footer>
  );
}

function ModalText({ setModalTexto, modalText, text, title }) {
  const classes = useStyles();
  return (
    <Dialog
      open={modalText}
      onClose={() => setModalTexto(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>{title}</h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent className='scroll'>
        <DialogContentText className={classes.textModal}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalTexto(false)} className={classes.btn} >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Contact({ callback, setModalContact, modalContact, setNombre, nombre, setCorreo, correo, setCelular, celular, setMensaje, mensaje, errores = [] }) {
  const classes = useStyles();
  const [wait, setWait] = useState(false);
  return (
    <Dialog
      open={modalContact}
      onClose={() => setModalContact(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>Contáctenos</h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent className='scroll'>
        {
          wait ? <WaitData /> :
            <div>
              {
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                      <Grid xs={12} sm={12} md={12}>
                        <TextField
                          onChange={({ target: { value } }) => setNombre(value)}
                          value={nombre}
                          label="Nombre"
                          error={getError('nombre', errores) && true}
                          helperText={getError('nombre', errores)}
                          fullWidth={true}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          onChange={({ target: { value } }) => setCorreo(value)}
                          value={correo}
                          error={getError('correo', errores) && true}
                          helperText={getError('correo', errores)}
                          label="Correo"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          onChange={({ target: { value } }) => setCelular(value)}
                          value={celular}
                          error={getError('celular', errores) && true}
                          helperText={getError('celular', errores)}
                          label="Ingresa tu número de teléfono"
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          onChange={({ target: { value } }) => setMensaje(value)}
                          value={mensaje}
                          label="Mensaje"
                          error={getError('mensaje', errores) && true}
                          helperText={getError('mensaje', errores)}
                          fullWidth={true}
                          multiline
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </div>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalContact(false)} color='default' >
          Cerrar
        </Button>
        <Button disabled={wait} onClick={() => callback(setWait)} className={classes.btn} >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DetailContent({ setModalDetailContent, modalDetailContent, data }) {
  let { nombre, descripcion, tipo_archivo, tipo, sub_tipo, fecha_publicacion, archivo, id } = data;
  const classes = useStyles();

  return (
    modalDetailContent &&
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <Button className={classes.btnBack} onClick={() => setModalDetailContent(false)} color="primary" startIcon={<ArrowBackIcon />}> <span style={{ color: 'black' }}>Volver</span> </Button>
        <Grid id="customized-dialog-title" className={classes.contentTitle}>
          <Hidden xsDown>
            <p className={classes.contentHeader}>{nombre}</p>
          </Hidden>
          <Hidden smUp>
            <p className={classes.contentHeaderApp}>{nombre}</p>
          </Hidden>
        </Grid>
        <Grid className='scroll'>
          <Grid >
            <Grid container spacing={3} className={classes.containerDetail}>
              {showMedia(classes.contentVideo, tipo_archivo, archivo)}
              <Grid item md={12} className={classes.containerTitle}>
                <p className={classes.contenCategory}>{tipo ? tipo.nombre : 'Ninguna'} - {sub_tipo ? sub_tipo.nombre : 'Ninguna'}</p>
                <p className={classes.contentPublished}>Publicado : <span>{fecha_publicacion}</span></p>
              </Grid>
              <Grid item md={12} className={classes.containerDescription}>
                <Typography variant="body2" color="textSecondary" component="p" className={classes.containerTitleDescription}>
                  {descripcion}
                </Typography>
              </Grid>
              <Grid item md={12} className={classes.containerShare}>
                <Button onClick={() => shareFacebook(nombre, id)} className={classes.btnFacebook} variant="contained" color="secondary" endIcon={<FacebookIcon />}> Compártelo </Button>
                <Button onClick={() => shareWhatsApp(id)} className={classes.btnWhatsapp} variant="contained" color="secondary" endIcon={<WhatsAppIcon />}> Compártelo</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function WaitData() {
  const classes = useStyles();
  return (
    <div className={classes.contentWait}>
      <img src={loaderBlack} alt="" className={classes.contentWaitImg} />
    </div>
  );
}

function Wait() {
  return (
    <div >
      <LinearProgress color="primary" />
      <div style={{ padding: "200px 0 0 0", width: "100%", textAlign: 'center' }}>
        <img src={loaderBlack} width="200" alt="" />
        <p>Cargando...</p>
      </div>
    </div>
  );
}

function CopyrightMore() {
  return (
    <div>
      <p>Estos servicios son prestados por Quicklii. Si requiere ayuda, soporte técnico o más información comuníquese en Colombia en las líneas de atención al cliente:</p>
      <ul>
        <li>018000180532</li>
        <li>customer.support@quicklii.co</li>
      </ul>
      <p>Todos los derechos reservados {new Date().getFullYear()}.</p>
    </div>
  );
}

export default function Template21({ dataService }) {
  const classes = useStyles();
  const [activeContent, setActiveContent] = useState('');
  const [waitPin, setWaitPin] = useState(false);
  const [pinSend, setPintSend] = useState(false);
  const [modalPin, setModalPin] = useState(false);
  const [pin, setPin] = useState('');
  const [accion, setAccion] = useState({});
  const params = useParams();
  const [wait, setWait] = useState(true);
  const [waitContent, setWaitContent] = useState(true);
  const [error, setError] = useState(false);
  const [errorContent, setErrorContent] = useState(false);
  const [tipoPF, setTipoPF] = useState(0);
  const [pk, setPk] = useState(params.id);
  const [service, setService] = useState({});
  const [dataContent, setDataContent] = useState({});
  const [categorys, setCategorys] = useState([]);
  const [subCategorys, setSubCategorys] = useState([]);
  const [filterCategory, setFilterCategory] = useState(false);
  const [content, setContent] = useState([]);
  const [modalTerms, setModalTerms] = useState(false);
  const [modalPoliticas, setModalPoliticas] = useState(false);
  const [modalDetailContent, setModalDetailContent] = useState(false);
  const [modalCopyrigth, setModalCopyrigth] = useState(false);
  const [modalQuestion, setModalQuestion] = useState(false);
  const [modalContact, setModalContact] = useState(false);
  const [nombre, setNombre] = useState('');
  const [correo, setCorreo] = useState('');
  const [celular, setCelular] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [errores, setErrores] = useState([]);
  const [publicity, setPublicity] = useState([]);

  useEffect(() => {
    getData(pk);
  }, []);

  const getData = async (pk) => {
    setWait(true);
    let { success, data: dataC } = await getCategorys(pk);
    if (success) {
      setService(dataService);
      setCategorys(dataC);
      getDataContent([], dataService);
    } else setError(true);
    setWait(false);
  }

  
  const sendPinPhone = async () => {
    setWaitPin(true);
    consulta(`contenido/pin`, { servicioId: pk, celular }, 'post', (error, estado, resp) => {
      if (estado == 200) {
        setPintSend(true);
        Toast.fire({ icon: 'success', title: resp.titulo });
      } else {
        setPintSend(false);
        Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
      }
      console.log(resp);
    }, false);
    setWaitPin(false);
  }

  const confirmPinPhone = async () => {
    setWaitPin(true);
    consulta(`contenido/confirmar`, { servicioId: pk, celular, codigo: pin }, 'post', (error, estado, resp) => {
      if (estado == 200) {
        Toast.fire({ icon: 'success', title: resp.titulo });
        setModalPin(false);
        setPin('');
        setCelular('');
        setActiveContent(celular);
        getDataContent([{ 'llave': 'activate', 'valor': celular }], service);
      } else {
        Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
      }
    }, false);
    setWaitPin(false);
  }


  const getDataContent = async (filters = [], serv = null) => {
    serv = !serv ? service : serv;
    let deactivePublicity = filters.find(element => element.llave == 'searching');
    let activate = filters.find(element => element.llave == 'activate');
    let filterCat = filters.find(element => element.llave == 'tipo');
    setFilterCategory(filterCat ? true : false);
    setWaitContent(true);
    if (filterCat) {
      let { success, data: dataC } = await getSubCategorys(filterCat.valor);
      if (success) setSubCategorys(dataC);
    }
    let { uId, contentPk } = await getPermissions();
    if (filters.length == 0 && contentPk) filters.push({ 'llave': 'id', 'valor': contentPk });
    filters.push({ 'llave': 'uId', 'valor': uId });
    filters.push({ 'llave': 'servicio', 'valor': pk });
    if (!activate && activeContent.length > 0) filters.push({ 'llave': 'activate', 'valor': activeContent });
    let { success: successCon, data: dataContenido, category,accion } = await getContentService(filters);
    if (accion && (accion.accion == 'pin' || accion.accion == 'info')) setModalPin(true);
    setAccion(accion);
    let { data: dataPublicity, success: successPublicity } = await getPublicity(pk);
    if (successCon) {
      //Set the data in this line code
      await addPublicity(dataContenido, dataPublicity, successPublicity, deactivePublicity);
      await addSuscription(dataContenido, serv, category);
      setTipoPF(category);
    } else setErrorContent(true);
    setWaitContent(false);
  }


  const addNotification = async (data, callback) => {

    let errores = await validateInputs([
      { value: nombre, name: 'nombre', err: { empty: true } },
      { value: correo, name: 'correo', err: { empty: true } },
      { value: celular, name: 'celular', err: { empty: true } },
      { value: mensaje, name: 'mensaje', err: { empty: true } },
    ]);
    if (errores.length == 0) {
      setErrores([]);
      callback(true);
      data = await crear_form_data(data);
      formulario("notificacion/crear", data, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            setNombre('');
            setMensaje('');
            setCorreo('');
            setCelular('');
            setModalContact(false);
            callback(false);
            Toast.fire({ icon: 'success', title: resp.titulo });
          } else {
            Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
            callback(false);
          }
        }, false
      );
    } else {
      setErrores([]);
      setErrores(errores);
    }

  }


  function TransformQuestion({ text }) {
    let list = text.search("!");
    let listF = text.search("¡");
    if (list != -1 && listF != -1) {
      let preguntas = text.split('!');
      return preguntas.map((e, i) => {
        let p = e.split('¡');
        if (p.length == 1) return '';
        return (
          <List key={i}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={``}
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {`${p[0]}`}
                    </Typography>
                    <p className={classes.textModal}>
                      {p[1]}
                    </p>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        );
      })
    } else return text;
  }

  function addPublicity(content, dataPublicity, succesPublicity, deactivePublicity) {
    let index = 0;
    return new Promise((resolve) => {
      if (!deactivePublicity && succesPublicity) {
        dataPublicity.map(({ principal: { archivo, valorc } }) => {
          let data = { archivo, valorc, tipo: 'publicidad' }
          // let ubi = Math.floor((Math.random() * ((content.length) - 0)) + 0);
          content.splice(index, 0, data);
          if (index < dataPublicity.length) index = index + 2;
        })
      }
      setContent(content);
      setPublicity(dataPublicity);
      resolve(true);
    });
  }

  function addSuscription(content, service, category) {
    let indice = 2;
    let name = service && service.nombre ? service.nombre.replace(/ /g, "") : 'portal';
    return new Promise((resolve) => {
      if (category == 1) {
        resolve(content);
      } else {
        let n = Math.ceil(content.length / 5);
        n = n == 0 ? 1 : n;
        for (let index = 0; index < n; index++) {
          let data = { nombre  :  service.valorr == 'ZED' ? 'Accede ahora mismo al contenido.' : 'Suscríbete ahora mismo! Y accede a todo el contenido.', 'link': `/suscripcion/${name}/${service.id}`, tipo: 'suscripcion' };
          content.splice(indice, 0, data);
          indice = indice + 5;
        }
        resolve(content);
      }
    });
  };

  function TransformText({ text }) {
    let list = text.search("!");
    let listF = text.search("¡");
    if (list != -1 && listF != -1) {
      let resp = text.split('¡');
      return resp.map((e, i) => {
        let p = e.split('!');
        if (p.length == 1) return '';
        return (
          <List key={i}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={``}
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"

                    >
                      {`${p[0]}`}
                    </Typography>
                    <p className={classes.textModal}>
                      {p[1]}
                    </p>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        );
      })
    } else return text;
  }


  if (wait) return <Wait />;
  else if ((!wait && error) || (!waitContent && errorContent)) return <NotFound />;
  else return (
    <div className={classes.mainPanel} style={{ backgroundImage: service.archivo5 && `url(${API}${service.archivo5})`, }}>
      {<SetTitle service={service} />}
      <MenuDesktop categorys={categorys} getDataContent={getDataContent} service={service} filterCategory={filterCategory} subCategorys={subCategorys} />
      {
        (filterCategory || modalDetailContent) ? '' :
          <Hidden xsDown>
            <Header service={service} />
          </Hidden>
      }
      {
        (filterCategory || modalDetailContent) ? '' :
          <Hidden smUp>
            <HeaderApp service={service} />
          </Hidden>
      }


      {/* <SearchDesktop categorys={categorys} getDataContent={getDataContent} service={service} /> */}
      {
        !modalDetailContent ?
          <div className={classes.mainPanelContent}>
            {
              waitContent ? < WaitData /> :
                <Grid container justify="center" alignItems="center">
                  {content.length == 0 && <Empty menssage={`No se encontro contenido.`} />}
                  {
                    content.map((e, i) => {
                      if (e.tipo == 'publicidad') {
                        return (
                          <Grid container justify="center" alignItems="center" key={i} item xs={12} sm={6} md={4} lg={3} xl={3} >
                            <Publicity data={e} />
                          </Grid>
                        )
                      } else if (e.tipo == 'suscripcion') {
                        return (
                          <Grid container justify="center" alignItems="center" key={i} item xs={12} sm={6} md={4} lg={3} xl={3} >
                            <Suscription data={e} />
                          </Grid>
                        )
                      } else {
                        return (
                          <Grid container justify="center" alignItems="center" key={i} item xs={12} sm={6} md={4} lg={3} xl={3} onClick={() => {
                            setDataContent(e);
                            setModalDetailContent(true);
                          }}>
                            <ShowContentCard subcategory={e.sub_tipo ? e.sub_tipo.nombre : 'Ninguna'} service={service} nombre={e.nombre} image={e.archivo2} category={e.tipo ? e.tipo.nombre : 'Gratis'} date={e.fecha_publicacion} description={e.descripcion} />
                          </Grid>
                        )
                      }
                    })
                  }
                </Grid>
            }
          </div >

          : <DetailContent setModalDetailContent={setModalDetailContent} modalDetailContent={modalDetailContent} data={dataContent} />
      }


      <Footer service={service} setModalQuestion={setModalQuestion} setModalTerms={setModalTerms} setModalPoliticas={setModalPoliticas} setModalCopyrigth={setModalCopyrigth} />
      <ModalText title='Términos y Condiciones' modalText={modalTerms} setModalTexto={setModalTerms} text={service && service.valore ? <TransformText text={service.valore} /> : ''} />
      <ModalText title='Políticas de privacidad' modalText={modalPoliticas} setModalTexto={setModalPoliticas} text={service && service.valorg ? <TransformText text={service.valorg} /> : ''} />
      <ModalText title='Copyright© Quicklii 2021' modalText={modalCopyrigth} setModalTexto={setModalCopyrigth} text={<CopyrightMore />} />
      <ModalText title='Preguntas Fecuentes' modalText={modalQuestion} setModalTexto={setModalQuestion} text={service && service.valori ? <TransformQuestion text={service.valori} /> : ''} />
      <Contact callback={(setWait) => addNotification({ nombre, mensaje, correo, celular, asunto: `NOTIFICACIÓN - ${service.nombre}` }, setWait)} errores={errores} setModalContact={setModalContact} modalContact={modalContact} setNombre={setNombre} nombre={nombre} setCorreo={setCorreo} correo={correo} setCelular={setCelular} celular={celular} setMensaje={setMensaje} mensaje={mensaje} />
      <Fab elevation={3} onClick={() => setModalContact(true)} className={classes.btnEmail} color="default" aria-label="edit"> <EmailIcon /> </Fab>
      <ModalPin waitPin={waitPin} pinSend={pinSend} modalPin={modalPin} setModalPin={setModalPin} accion={accion} pin={pin} setPin={setPin} celular={celular} setCelular={setCelular} errores={errores} callback={() => sendPinPhone()} callback2={() => confirmPinPhone()} />
    </div >
  );
}
