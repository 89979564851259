
const appStyle = (theme,) => ({
  mainPanel: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: "auto",
    position: "relative",
    float: "right",
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    backgroundColor: '#F8F9FA',
  },
  header: {
    width: "350px",
    textAlign: 'left',
    color: '#fff',
    height: '180px',
    margin: "0 auto",
  },
  headerApp: {
    width: "100%",
    margin: '0px 0 20px 0px',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundPosition: 'cover',
    backgroundSize: '100% 100%',
    height: '552px',
  },
  headerContentIcon: {
    width: '10px',
    float: 'left',
    padding: "30px 60px 0 0",
  },
  headerIcon: {
    width: '80px',
    height: "50px",
    color: '#006738',
    "&:hover": {
      backgroundColor: 'white',
      cursor: 'pointer',
    },

  },
  headerContent: {
    float: 'left',
    margin: "0 auto",
    width: '195px',
    height: "180px",
    backgroundImage: `url("https://shopdisneyeu.scene7.com/is/image/DisneyStoreES/34819_marvel_mania_tb?$tb$")`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundPosition: 'cover',
    backgroundSize: '100% 100%',
  },
  headerTitle1: {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: "0 auto",
    width: "55%",
    paddingTop: 20,
    textAlign: 'center',
  },
  headerTitle2: {
    fontFamily: 'Montserrat',
    fontSize: '89px',
    fontWeight: 'bold',
    margin: "0 auto",
    width: "55%",
    textAlign: 'center',
  },
  headerSecondTitle: {
    color: '#006738',
    lineHeight: '50px',
  },
  menu: {
    width: "100%",
    height: '122px',
    padding: "15px 15px 15px 40px",
    backgroundColor: 'white',
  },
  chipLogo: {
    height: '51px',
    width: '149px',
    textAlign: 'center',
  },
  chip: {
    color: "#747480",
    fontFamily: 'Poppins',
    fontWeight: '900',
    fontSize: '13px',
    margin: "0px 5px 0 5px",
    borderColor: `rgb(255,152,109)`,
    "&:hover": {
      color: 'black'
    },
  },

  headerChip: {
    border: '1px solid #BBBDBF',
    opacity: 0.5,
    borderRadius: '4px',
    backgroundColor: '#BBBDBF',
    color: "white",
    fontFamily: 'Poppins',
    margin: "0px 5px 0 5px",
    height: '30px',
    width: '133px',
    paddingTop: 4,
  },

  chips: {
    marginTop: 30
  },

  textSearch: {
    padding: "0 30px 0px 0px",
    fontFamily: 'Montserrat',
    fontSize: '15px',
  },
  mainPanelContent: {
    width: "95%",
    margin: "10px 0px 0px 10px",
  },
  cardIcon: {
    width: 30,
  },
  cardRootPublicity: {
    backgroundColor: 'transparent',
    maxWidth: 368,
    width: 368,
    maxHeight: 200,
    margin: "5px 5px 10px 5px",
    cursor: 'pointer',
    "&:hover": {
      boxShadow: `0 0 20px #000000`,
    },
  },

  cardRoot: {
    maxWidth: 368,
    width: 368,
    minHeight: 370,
    height: 370,
    margin: "5px 5px 10px 5px",
    cursor: 'pointer',
    "&:hover": {
      boxShadow: `0 0 20px #000000`,
    },
  },

  cardRootOther: {
    maxWidth: 397,
    width: 397,
    minHeight: 177,
    height: 177,
    margin: "5px 5px 10px 5px",
    cursor: 'pointer',
    "&:hover": {
      boxShadow: `0 0 20px #000000`,
    },
  },
  cardMedia: {
    height: "100%",
    width: "100%"
  },
  cardMediaHeader: {
    position: 'absolute',
    marginLeft: 10,
  },
  cardContent: {
    backgroundColor: 'white',
    paddingBottom: 3,
    height: "100%"
  },

  cardTitle: {
    fontFamily: 'Montserrat',
    color: '#4A4A4A',
    fontSize: 16,
    fontWeight: "bold",
    margin: '0px 0 3px 0',
  },
  cardTitleDate: {
    fontFamily: 'Montserrat',
    color: '#4A4A4A',
    fontSize: 13,
    padding: '4px 0 3px 0',
    marginBottom: 5,
  },
  cardTitleDateDay: {
    fontFamily: 'Montserrat',
    color: '#006738',
    fontSize: 36,
    padding: '4px 0 3px 0',
    float: 'left',
  },

  cardTitleDateBorde: {
    width: 1,
    height: 18,
    margin: 8,
    padding: 0,
    color: "#006738",
    backgroundColor: "#006738",
    float: 'left',
  },

  cardTitleDateIcon: {
    width: 25,
    height: 15,
    margin: "8px 5px 0px 3px",
    float: 'left',
  },

  cardSubTitle: {
    fontFamily: 'Montserrat',
    color: '#4A4A4A',
    lineHeight: '15px',
    lettr: 'normal',
    padding: 0,
    margin: 0,
    fontWeight: 'bold',
    float: 'left',
    fontSize: 12,
  },
  cardList: {
    color: '#4A4A4A',

    fontSize: 12,
  },
  cardText: {
    height: 70,
    fontFamily: 'Montserrat',
    color: '#4A4A4A',
    textAling: 'justify',
    fontSize: 12,
    marginBottom: 5
  },
  divider: {
    width: "100%",
    height: "10px",
    backgroundColor: 'red',
  },
  footer: {
    marginTop: "50px",
    paddingTop: '20px',
    backgroundImage: 'linear-gradient(180deg, #006738 0%, #003117 100%)',
    textAlign: 'center',
    color: 'white',
    width: "100%"
  },
  SocialIcons: {
    margin: '20px 20px 30px 0',
    color: 'white',
    width: 15
  },
  copyright: {
    fontFamily: 'Montserrat',
    fontSize: '13px',
    marginTop: 20,
    marginBottom: 20,
  },
  footerContentText: {
    marginTop: 30,
  },
  footerText: {
    fontFamily: 'Montserrat',
    // margin: 5,
    fontSize: "70%",
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: 30,
    padding: 10,
    "&:hover": {
      background: `rgba(0, 0, 0, 0.5)`,
    },
  },
  rootSearch: {
    padding: '0px 4px 0 30px',
    display: 'flex',
    alignItems: 'center',
    width: "90%",
    backgroundColor: '#EEEEEE',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    backgroundColor: 'white',
    paddingLeft: 30,
    borderColor: '#EEEEEE',
    borderRadius: 30,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  rootAppbar: {
    flexGrow: 1,
    marginBottom: 60,
    backgroundColor: 'white',
  },

  appbar: {
    backgroundColor: 'white',
    boxShadow: `2 0 4px 0px #000000`,
  },
  iconAppbar: {
    color: '#006738'
  },
  iconAppbarMenu: {
    color: 'white'
  },

  menuButtonAppbar: {
    marginRight: theme.spacing(2),
  },
  titleAppbar: {
    flexGrow: 1,
    textAlign: 'center'
  },
  imgAppbarApp: {
    width: 67,
    maxHeight: 33,
    marginTop: 5
  },
  imgAppbar: {
    width: 167,
    maxHeight: 81,
    marginTop: 5
  },
  rootSearchApp: {
    margin: '0px 6px 0 6px',
    // padding: '0px 20px 0 10px',
    display: 'flex',
    alignItems: 'center',
    width: "95%",
    backgroundColor: '#EEEEEE',
    borderBottom: "1px solid white"
  },
  textApp: {
    marginLeft: theme.spacing(1),
    flex: 1,
    textAlign: 'right',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: "bold",
  },
  margin: {
    margin: "0 !important",
    padding: "0 !important"
  },
  contentWait: {
    margin: "100px 0px 100px 0",
    width: "100%",
    textAlign: 'center',
  },
  contentWaitImg: {
    width: "10%",
    minWidth: "150px",
    padding: 0,
    margin: 0,
  },

  contentWaitText: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    position: 'absolute',
    top: "50%",
    bottom: "50%",
    left: "45%",
    right: "55%",
    color: 'red'
  },
  IconFooter: {
    width: "20%",
    minWidth: "150px",
    maxHeight: 150,
    padding: 0,
    margin: 0,
  },
  btn: {
    background: `#006738`,
    color: 'white'
  },
  btnFacebook: {
    backgroundColor: `#3b5998`,
    color: 'white',
    margin: 10,
    "&:hover": {
      background: `rgb(241,231,231)`,
      color: 'black',
    },
  },
  btnBack: {
    marginTop: 30,
    marginLeft: 50,
    textAlign: 'left',
    width: 100,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#F6931D',
  },
  contentTitle: {
    borderTop: "1px solid #DFDFDF",
    margin: "10px 53px 0px 53px",
  },
  btnWhatsapp: {
    backgroundColor: `#00bb2d`,
    color: 'white',
    margin: 10,
    "&:hover": {
      background: `rgb(241,231,231)`,
      color: 'black',
    },
  },
  containerShare: {
    textAlign: 'center',
  },
  containerMedia: {
    textAlign: 'center',
    // background: `#006738`,
  },
  contenCategory: {
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#006738',
    fontSize: 20,
  },
  containerTitleDescription: {
    fontFamily: 'Montserrat',
    fontSize: "14px",
    textAlign: "justify",
    color: '#4B4B4B',
    lineHeight: '25px',
  },
  contentHeader: {
    // color: '#006738',
    fontFamily: 'Montserrat',
    padding: 0,
    margin: 0,
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  contentHeaderApp: {
    // color: '#006738',
    fontFamily: 'Montserrat',
    padding: 0,
    margin: 0,
    fontSize: 13,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  headerTitleMenu: {
    // color: '#006738',
    fontFamily: 'Montserrat',
    fontSize: 20,
    padding: 20,
    marginBottom: 10,
    textAlign: 'center',
    borderBottom: "1px solid #EEEEEE",

  },
  contentVideo: {
    width: "100%",
    height: "100%",
    color: 'white',
    maxHeight: "350px"
  },
  btnEmail: {
    zIndex: 1000,
    position: "fixed",
    top: "85%",
    right: 10,
    backgroundColor: '#F6931D',
    color: 'white',
  },
  cardTitleModal: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  cardHeaderModal: {
    boxShadow: `-5px -10px 1px #F6931D`,
    background: `#006738`
  },
  textModal: {
    textAlign: 'justify',
  },
  contentPublished: {
    fontFamily: 'Montserrat',
  },
  containerDetail: {
    padding: "10px 30px 10px 30px",
    // backgroundColor: 'red',
  },
  IconDrawerMenu: {
    width: 200,
    margin: 30
  },
  DrawerMenuAppBar: {
    backgroundColor: 'white',
  },
  chipApp: {
    color: '#CCCCCC',
    fontFamily: 'Poppins',
    padding: 5,
    margin: "7px 10px 7px 10px",
    borderRadius: "5px",
  },
  cardRootSuscription: {
    cursor: 'pointer',
    background: '#006738',
    maxWidth: 368,
    width: 368,
    minHeight: 370,
    height: 370,
    margin: "5px 5px 10px 5px",
  },
  cardRootSuscriptionBorder: {
    background: '#F6931D',
    maxWidth: 368,
    width: 368,
    height: 10,
  },
  cardRootSuscriptionMensaje: {
    height: '140px',
    width: '267px',
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '30px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '35px',
    margin: "0 auto",
    paddingTop: "30px",
  },
  cardRootSuscriptionBtn: {
    margin: "15px 0px 30px 0",
    height: '60px',
    width: '100%',
    color: "black",
    background: 'white',
    fontFamily: 'Montserrat',
  },
});

export default appStyle;
