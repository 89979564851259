import React from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import Store from "@material-ui/icons/Store";
import CollectionsIcon from "@material-ui/icons/Collections";
import ApartmentIcon from "@material-ui/icons/Apartment";
import CameraIcon from "@material-ui/icons/Camera";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import ExtensionIcon from "@material-ui/icons/Extension";
import FlagIcon from "@material-ui/icons/Flag";
import HomeIcon from "@material-ui/icons/Home";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import Muted from "../components/Typography/Muted.js";
import empty from "../assets/img/default.png";
import Not_found from "../assets/img/not_found.gif";
import loaderBlack from "../assets/img/loaderBlack.gif";

//export const API = "http://localhost:8085";
export const API = "https://portalbeapi.quicklii.co";

export const consulta = (
  url,
  data = null,
  method = null,
  callback,
  authorization = true
) => {
  let parametros = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (method) parametros.method = method === "patch" ? "PATCH" : method;
  if (authorization)
    parametros.headers.Authorization = `JWT ${localStorage.getItem("token")}`;
  if (data) parametros.body = JSON.stringify(data);

  fetch(`${API}/api/v1.0/${url}`, parametros)
    .then(async (response) => {
      const estado = response.status;
      const resp = await response.json();
      if (estado == 401 && authorization) sinSesion();
      callback(null, estado, resp);
    })
    .catch((error) => callback(error));
};

export const mostrarError = (errores) => {
  for (var k in errores) {
    let r = errores[k];
    let mensaje = "";
    r.forEach((element) => {
      mensaje = `${mensaje} ${element}`;
    });
    return `${k} : ${mensaje}`;
  }
};

export const formulario = (
  url,
  data,
  method,
  callback,
  authorization = true
) => {
  let headers = {};
  if (authorization)
    headers.Authorization = `JWT ${localStorage.getItem("token")}`;

  fetch(`${API}/api/v1.0/${url}`, {
    method: method === "patch" ? "PATCH" : method,
    cache: "no-cache",
    mode: "cors",
    body: data,
    headers,
  })
    .then(async (response) => {
      const estado = response.status;
      const resp = await response.json();
      if (estado == 401 && authorization) sinSesion();
      callback(null, estado, resp);
    })
    .catch((error) => callback(error));
};

const sinSesion = () => {
  localStorage.setItem("token", "");
  window.location.href = "/admin/ingresar";
};

export const crear_form_data = (data) => {
  return new Promise((resolve) => {
    let key = Object.keys(data);
    let formData = new FormData();
    key.forEach((key) => formData.append(key, data[key]));
    resolve(formData);
  });
};

export const transfornDataSelet = (data) => {
  return data.map((e) => ({
    value: e.id,
    label: e.nombre,
    codigo: e.codigo,
  }));
};

export const transfornDataSeletPermission = (data) => {
  return data.map(({ principal }) => ({
    value: principal.id,
    label: principal.nombre,
    codigo: principal.codigo,
  }));
};

export const transfornDataSeletPermissionSecundario = (data) => {
  return data.map(({ secundario }) => ({
    value: secundario.id,
    label: secundario.nombre,
    codigo: secundario.codigo,
  }));
};

export const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const colorDinamic = () => {
  let n = parseInt(Math.random() * (4 - 0) + 0);
  switch (n) {
    case 0:
      return { color: "danger" };
      break;
    case 1:
      return { color: "warning" };
      break;
    case 2:
      return { color: "success" };
      break;
    case 3:
      return { color: "info" };
      break;
    default:
      return { color: "info" };
      break;
  }
};

export const iconoDinamic = (n) => {
  switch (n) {
    case 0:
      return { icon: () => <Store /> };
      break;
    case 1:
      return { icon: () => <CollectionsIcon /> };
      break;
    case 2:
      return { icon: () => <HomeWorkIcon /> };
      break;
    case 3:
      return { icon: () => <ApartmentIcon /> };
      break;
    case 4:
      return { icon: () => <AccountBalanceIcon /> };
      break;
    case 5:
      return { icon: () => <EmojiEventsIcon /> };
      break;
    case 6:
      return { icon: () => <ExtensionIcon /> };
      break;
    case 7:
      return { icon: () => <FlagIcon /> };
      break;
    case 8:
      return { icon: () => <HomeIcon /> };
      break;
    case 9:
      return { icon: () => <ImportContactsIcon /> };
      break;
    case 10:
      return { icon: () => <InsertChartIcon /> };
      break;
    default:
      return { icon: () => <CameraIcon /> };
      break;
  }
};

export const getError = (llave, errores) => {
  let existe = errores.find((e) => e.llave === llave);
  return existe ? existe.mensaje : "";
};

export function Empty({ menssage = "Esta bandeja esta vacía" }) {
  return (
    <div
      style={{
        padding: "5% 0 5% 0",
        margin: 0,
        width: "100%",
        textAlign: "center",
      }}
    >
      <Muted
        children={
          <div>
            <img src={empty} height="150" alt="Sin datos" />
            <p>{menssage}</p>
          </div>
        }
      />
    </div>
  );
}

export function NotFound({ minHeight = "30hv" }) {
  return (
    <div
      style={{
        padding: "30px 0 30px 0",
        width: "100%",
        textAlign: "center",
        backgroundColor: "white",
        overflowX: "hidden",
      }}
    >
      <div>
        <img
          src={Not_found}
          style={{ width: "50%", minWidth: 300 }}
          alt="Sin datos"
        />
        <h4>Pagina no Encontrada</h4>
      </div>
    </div>
  );
}

export function Wait({ menssage = "Cargando..." }) {
  return (
    <div
      style={{
        padding: "5% 0 5% 0",
        margin: 0,
        width: "100%",
        textAlign: "center",
      }}
    >
      <Muted
        children={
          <div>
            <img src={loaderBlack} height="150" alt="Sin datos" />
            <p>{menssage}</p>
          </div>
        }
      />
    </div>
  );
}

export function SetTitle({ service = null }) {
  return (
    <>
      {service && (
        <Helmet>
          <title>{service.nombre.toLowerCase()}</title>
        </Helmet>
      )}
    </>
  );
}

export const CerrarApp = () => {
  return new Promise((resolve) => {
    localStorage.removeItem("token");
    window.location.href = "./ingresar";
    resolve(true);
  });
};

export const generarFiltros = (filtros = []) => {
  return new Promise((resolve) => {
    let f = "";
    filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
    resolve(f);
  });
};

export const validateInputs = (campos = []) => {
  return new Promise((resolve) => {
    let errores = [];
    campos.map(({ value, name, err }) => {
      if (err.empty && value.length == 0)
        errores.push({ llave: name, mensaje: `Este campo es obligatorio` });
      if (err.numeric && isNaN(value))
        errores.push({ llave: name, mensaje: `Este campo debe ser un numero` });
    });

    resolve(errores);
  });
};

export const getPublicity = (pk) => {
  return new Promise((resolve) => {
    consulta(
      `valores/permisos?secundario=${pk}&principal__generica=4`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200) resolve({ success: true, data: resp });
        else resolve({ success: false });
      },
      false
    );
  });
};

export const getService = (pk) => {
  return new Promise((resolve) => {
    consulta(
      `servicio/${pk}`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200)
          resolve({
            success: true,
            data: resp.data,
            celular: resp.celular,
            integracion: resp.integracion,
            trivia: resp.trivia,
          });
        else resolve({ success: false });
      },
      false
    );
  });
};

export const getServiceSimple = (pk, search = "") => {
  return new Promise((resolve) => {
    consulta(
      `generica/${pk}/servicios?search=${search}`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200) resolve({ success: true, data: resp });
        else resolve({ success: false });
      },
      false
    );
  });
};

export const getCategorys = (pk) => {
  return new Promise((resolve) => {
    consulta(
      `servicio/${pk}/categorias`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200) resolve({ success: true, data: resp.categorias });
        else resolve({ success: false });
      },
      false
    );
  });
};
export const getSubCategorys = (pk) => {
  return new Promise((resolve) => {
    consulta(
      `valores/permisos?principal=${pk}&principal__generica=2&secundario__generica=2`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200) resolve({ success: true, data: resp });
        else resolve({ success: false });
      },
      false
    );
  });
};

export const getContentService = async (f) => {
  const isOrdering = f.find((element) => element.llave == "ordering");
  const defaultOrder =
    !!isOrdering === false ||
    (!!isOrdering && isOrdering.valor === "-fecha_publicacion");
  let filtros = await generarFiltros(f);
  return new Promise((resolve) => {
    consulta(
      `contenido?${filtros}`,
      null,
      null,
      async (error, estado, resp) => {
        if (estado === 200) {
          let data = resp.data;
          if (defaultOrder) {
            data = await data.sort(function (a, b) {
              return (
                new Date(b.fecha_publicacion) - new Date(a.fecha_publicacion)
              );
            });
          }
          resolve({
            success: true,
            data,
            category: resp.category,
            accion: resp.accion,
          });
        } else resolve({ success: false });
      },
      false
    );
  });
};

export const getPermissions = async () => {
  return new Promise((resolve) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let uId = urlParams.get("uId");
    let tel = urlParams.get("tel");
    let contentPk = "";
    if (uId) {
      let uri = uId.split("-");
      uId = uri[0];
      contentPk = uri[1];
    }
    resolve({ uId, contentPk, tel });
  });
};

export const showMedia = (
  classe,
  tipo_archivo,
  archivo,
  descargar = true,
  style = {}
) => {
  let media = "";
  if (!archivo) return media;
  switch (tipo_archivo) {
    case "video":
      media = (
        <video
          className={classe}
          controls
          playsInline
          controlslist={descargar ? "download" : "nodownload"}
          style={style}
        >
          <source src={`${API}${archivo}`} type="video/mp4" />
          <source src={`${API}${archivo}`} type="video/ogg" />
        </video>
      );
      break;
    case "image":
      media = <img className={classe} src={`${API}${archivo}`} />;
      break;
    default:
      media = (
        <Button
          onClick={() => window.open(`${API + archivo}`)}
          className={classe}
        >
          No se pudo mostrar el contenido, clic aquí para abrir
        </Button>
      );
      break;
  }
  return media;
};

export const shareFacebook = (nombre, id) =>
  window.open(
    `http://www.facebook.com/sharer.php?u=${`${window.location}-${id}`}&t=${nombre}`
  );
export const shareWhatsApp = (id) =>
  window.open(
    `https://api.whatsapp.com/send?text=${`${window.location}-${id}`}`
  );

export const getTextMonth = (month) => {
  let text = "";
  switch (month) {
    case "01":
      text = "Enero";
      break;
    case "02":
      text = "Febrero";
      break;
    case "03":
      text = "Marzo";
      break;
    case "04":
      text = "Abril";
      break;
    case "05":
      text = "Mayo";
      break;
    case "06":
      text = "Junio";
      break;
    case "07":
      text = "Julio";
      break;
    case "08":
      text = "Agosto";
      break;
    case "09":
      text = "Septiembre";
      break;
    case "10":
      text = "Octubre";
      break;
    case "11":
      text = "Noviembre";
      break;
    case "12":
      text = "Diciembre";
      break;
    default:
      text = "Invalido";
      break;
  }
  return text;
};
