import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  Fab,
  Drawer,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  LinearProgress,
  CardContent,
  Typography,
  Paper,
  InputBase,
  Hidden,
  IconButton,
  AppBar,
  Tabs,
  Tab,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import FacebookIcon from "@material-ui/icons/Facebook";
import styles from "../assets/jss/material-dashboard-react/layouts/Template39Style.js";
import loaderBlack from "../assets/img/loaderBlack.gif";
import imgDefault from "../assets/img/default.png";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EmailIcon from "@material-ui/icons/Email";
import CardHeader from "../components/Card/CardHeader.js";

//Funtions
import {
  consulta,
  SetTitle,
  shareWhatsApp,
  shareFacebook,
  getPublicity,
  showMedia,
  getCategorys,
  getContentService,
  getPermissions,
  API,
  Empty,
  crear_form_data,
  formulario,
  NotFound,
  Toast,
  validateInputs,
  getError,
  mostrarError,
} from "../funciones/general";

const useStyles = makeStyles(styles);

function ModalPin({
  setModalPin,
  modalPin,
  accion,
  pin,
  setPin,
  celular,
  setCelular,
  errores,
  callback,
  callback2,
  waitPin,
  pinSend,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={modalPin}
      onClose={() => setModalPin(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>Validar teléfono </h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent className="scroll">
        <DialogContentText className={classes.textModal}>
          {waitPin ? (
            <WaitData />
          ) : (
            <div>
              {accion.accion == "pin" ? (
                <div>
                  {
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        {!pinSend ? (
                          <Grid container>
                            <Grid xs={12} sm={12} md={12}>
                              <TextField
                                onChange={({ target: { value } }) =>
                                  setCelular(value)
                                }
                                value={celular}
                                label="Ingresa tu número de teléfono"
                                error={getError("celular", errores) && true}
                                helperText={getError("celular", errores)}
                                fullWidth={true}
                                required
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              <TextField
                                onChange={({ target: { value } }) =>
                                  setPin(value)
                                }
                                value={pin}
                                error={getError("setPin", errores) && true}
                                helperText={getError("setPin", errores)}
                                label="Ingresar código"
                                fullWidth
                                required
                              />
                              <p>
                                Hemos enviado un código de confirmación a tu
                                celular, por favor revísalo.
                              </p>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  }
                </div>
              ) : (
                accion.mensaje
              )}
            </div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalPin(false)} color="default">
          Cerrar
        </Button>
        {accion.accion == "pin" && (
          <Button
            disabled={waitPin}
            onClick={() => (!pinSend ? callback() : callback2())}
            className={classes.btn}
          >
            Aceptar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

function Header({ service }) {
  const classes = useStyles();
  return (
    <Grid
      className={classes.header}
      style={{ backgroundImage: `url("${API}${service.archivo}")` }}
    >
      {service.valorf && (
        <div className={classes.headerContent}>
          <h3 className={classes.headerTitle}>{service.valorf}</h3>
        </div>
      )}
    </Grid>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function Modalsearch({
  category,
  ordering,
  getData,
  setModalSearching,
  Modalsearching,
}) {
  const classes = useStyles();
  const [searching, setsearching] = React.useState("");
  return (
    <Dialog open={Modalsearching} onClose={() => setModalSearching(false)}>
      <DialogTitle>
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>Buscar Contenido</h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TextField
            value={searching}
            onChange={({ target: { value } }) => setsearching(value)}
            onKeyDown={(e) =>
              e.key === "Enter" && getData({ category, ordering, searching })
            }
            label="Buscar"
            fullWidth
            color="secondary"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalSearching(false)}> Cerrar </Button>
        <Button
          onClick={() => getData({ category, ordering, searching })}
          className={classes.btn}
        >
          {" "}
          Aceptar{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function MenuDesktop({ categorys, getDataContent, service }) {
  const classes = useStyles();

  const [category, setCategory] = React.useState(0);
  const [ordering, setordering] = React.useState("-fecha_publicacion");
  const [searching, setsearching] = React.useState("");
  const [Modalsearching, setModalSearching] = React.useState(false);

  const [drawerMenu, setDrawerMenu] = React.useState(false);

  const getData = ({ searching = "", category = 0, ordering = "" }) => {
    let filters = [];
    if (category > 0)
      filters.push({ llave: "tipo", valor: categorys[category - 1][1] });
    if (ordering) filters.push({ llave: "ordering", valor: ordering });
    if (searching) filters.push({ llave: "searching", valor: searching });
    getDataContent(filters);
    return false;
  };

  const isCheck = (category) => ({
    background: category && `#eb2438`,
    color: category && `white`,
    fontWeight: category && `bold`,
  });

  const handleChange = (event, newValue) => {
    setCategory(newValue);
    getData({ category: newValue, ordering, searching });
  };

  return (
    <div>
      <Hidden xsDown>
        <Grid container className={classes.menu}>
          <Grid
            item
            xs={8}
            className={classes.chips}
            sm={12}
            md={6}
            lg={8}
            xl={8}
          >
            <AppBar position="static" color="default" elevation={0}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  key={0}
                  className={classes.chip}
                  style={isCheck(category == 0)}
                  label="Inicio"
                  {...a11yProps(0)}
                />
                {categorys.map((e, i) => {
                  return (
                    <Tab
                      key={i + 1}
                      className={classes.chip}
                      style={isCheck(category == i + 1)}
                      label={e[0]}
                      {...a11yProps(i + 1)}
                    />
                  );
                })}
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Paper className={classes.rootSearch} elevation={0}>
              {ordering == "-fecha_publicacion"
                ? "Más Recientes"
                : "Más Antiguos"}
              <IconButton
                onClick={() => {
                  let ord =
                    ordering == "-fecha_publicacion"
                      ? "fecha_publicacion"
                      : "-fecha_publicacion";
                  setordering(ord);
                  getData({ category, ordering: ord, searching });
                }}
                className={classes.iconButton}
                aria-label="menu"
              >
                <UnfoldMoreIcon />
              </IconButton>
              <InputBase
                onChange={({ target: { value } }) => setsearching(value)}
                onKeyDown={(e) =>
                  e.key === "Enter" &&
                  getData({ category, ordering, searching })
                }
                className={classes.input}
                placeholder="Buscar..."
                inputProps={{ "aria-label": "search google maps" }}
              />
              <IconButton
                onClick={() => getData({ category, ordering, searching })}
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <div className={classes.rootAppbar}>
          <AppBar
            position="fixed"
            className={classes.appbar}
            elevation={0}
            style={{ backgroundImage: `url(${API}${service.archivo3})` }}
          >
            <Toolbar>
              <IconButton
                onClick={() => setDrawerMenu(true)}
                edge="start"
                className={classes.menuButtonAppbar2}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon className={classes.iconAppbar} />
              </IconButton>
              <Typography variant="h6" className={classes.titleAppbar}>
                {/* <img src={`${API}${service.archivo3}`} alt="" className={classes.imgAppbar} /> */}
              </Typography>
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setModalSearching(true)}
                  color="inherit"
                >
                  <SearchIcon className={classes.iconAppbarSearch} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </div>
        <Paper className={classes.rootSearchApp} elevation={0}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.textApp}
          >
            {ordering == "-fecha_publicacion"
              ? "Más Recientes"
              : "Más Antiguos"}
          </Typography>
          <IconButton
            onClick={() => {
              let ord =
                ordering == "-fecha_publicacion"
                  ? "fecha_publicacion"
                  : "-fecha_publicacion";
              setordering(ord);
              getData({ category, ordering: ord, searching });
            }}
            className={classes.iconButton}
            aria-label="search"
          >
            <UnfoldMoreIcon />
          </IconButton>
        </Paper>
        <Drawer
          className="menuTemp1"
          anchor="left"
          open={drawerMenu}
          onClose={() => setDrawerMenu(false)}
        >
          <AppBar
            className={classes.DrawerMenuAppBar}
            position="initial"
            elevation={0}
          >
            <Toolbar>
              <IconButton
                onClick={() => setDrawerMenu(false)}
                edge="start"
                className={classes.menuButtonAppbar}
                color="inherit"
                aria-label="menu"
              >
                <CloseIcon className={classes.iconAppbar} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Grid container justify="center" alignItems="center">
            <img
              src={`${API}${service.archivo4}`}
              alt=""
              className={classes.IconDrawerMenu}
            />
          </Grid>
          <span
            key={0}
            className={classes.chipApp}
            style={isCheck(category == 0)}
            onClick={() => handleChange(null, 0)}
          >
            Inicio
          </span>
          {categorys.map((e, i) => {
            return (
              <span
                onClick={() => handleChange(null, i + 1)}
                key={i + 1}
                className={classes.chipApp}
                style={isCheck(category == i + 1)}
              >
                {e[0]}
              </span>
            );
          })}
        </Drawer>
      </Hidden>
      <Modalsearch
        category={category}
        ordering={ordering}
        getData={getData}
        setModalSearching={setModalSearching}
        Modalsearching={Modalsearching}
      />
    </div>
  );
}

function ShowContentCard({
  category = "",
  description = "",
  date = "",
  image = "",
}) {
  category = category.length > 25 ? `${category.substr(0, 25)}...` : category;
  const classes = useStyles();
  return (
    <Card className={classes.cardRoot}>
      <div style={{ height: 190 }}>
        <img
          className={classes.cardMedia}
          src={image ? `${API}${image}` : imgDefault}
          alt="Imagen Contentido"
        />
      </div>
      <CardContent className={classes.cardContent}>
        <p className={classes.cardTitle}>{category}</p>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.cardText}
        >
          {description.slice(0, 110)}
          {description.length > 110 ? " ..." : "."}
        </Typography>
        <p className={classes.cardTitleDate}>
          Publicado : <span className={classes.cardSubTitle}>{date}</span>
        </p>
      </CardContent>
    </Card>
  );
}

function Publicity({ data }) {
  let { archivo, valorc } = data;
  const classes = useStyles();
  return (
    <Card
      className={classes.cardRootSuscription}
      onClick={() => valorc && window.open(valorc)}
    >
      <div className={classes.cardRootSuscriptionBorder}></div>
      <div style={{ height: 190 }}>
        <img
          className={classes.cardMedia}
          src={archivo ? `${archivo}` : imgDefault}
          alt="Imagen Publicidad"
        />
      </div>
      <CardContent className={classes.cardContent}>
        <Button variant="contained" className={classes.cardRootSuscriptionBtn}>
          Haz Clic Aquí
        </Button>
      </CardContent>
    </Card>
  );
}

function Suscription({ data }) {
  let { link, nombre } = data;
  const classes = useStyles();
  return (
    <Card className={classes.cardRootSuscription}>
      <div className={classes.cardRootSuscriptionBorder}></div>
      <div style={{ height: 190 }}>
        <p className={classes.cardRootSuscriptionMensaje}>{nombre}</p>
      </div>
      <CardContent className={classes.cardContent}>
        <Button
          variant="contained"
          onClick={() => link && window.open(link)}
          className={classes.cardRootSuscriptionBtn}
        >
          Suscríbete ahora
        </Button>
      </CardContent>
    </Card>
  );
}

function Footer({
  setModalTerms,
  setModalPoliticas,
  setModalCopyrigth,
  service,
  setModalQuestion,
}) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <img
        src={`${API}${service.archivo2}`}
        alt=""
        className={classes.IconFooter}
      />
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.footerContentText}
      >
        {service.valorr == "ZED" || service.valorr == "ZED_DIGITEL" ? (
          ""
        ) : (
          <Grid onClick={() => setModalPoliticas(true)}>
            <span className={classes.footerText}>Politicas de privacidad</span>
          </Grid>
        )}
        <Grid onClick={() => setModalTerms(true)}>
          <span className={classes.footerText}>Términos y condiciones</span>
        </Grid>
        {service.valorr == "ZED" || service.valorr == "ZED_DIGITEL" ? (
          ""
        ) : (
          <Grid onClick={() => setModalQuestion(true)}>
            <span className={classes.footerText}>Preguntas Frecuentes</span>
          </Grid>
        )}
        {(service.valorr == "ZED" || service.valorr == "ZED_DIGITEL") && (
          <Grid>
            <span className={classes.footerText}>SALIR</span>
          </Grid>
        )}
      </Grid>
      <div className={classes.copyright}>
        Copyright© Quicklii {new Date().getFullYear()} -
        <span
          onClick={() => setModalCopyrigth(true)}
          className={classes.footerText}
        >
          VER MÁS
        </span>
      </div>
    </footer>
  );
}

function ModalText({ setModalTexto, modalText, text, title }) {
  const classes = useStyles();
  return (
    <Dialog
      open={modalText}
      onClose={() => setModalTexto(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>{title}</h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent className="scroll">
        <DialogContentText className={classes.textModal}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalTexto(false)} className={classes.btn}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Contact({
  callback,
  setModalContact,
  modalContact,
  setNombre,
  nombre,
  setCorreo,
  correo,
  setCelular,
  celular,
  setMensaje,
  mensaje,
  errores = [],
}) {
  const classes = useStyles();
  const [wait, setWait] = useState(false);
  return (
    <Dialog
      open={modalContact}
      onClose={() => setModalContact(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <CardHeader className={classes.cardHeaderModal}>
          <h4 className={classes.cardTitleModal}>Contáctenos</h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent className="scroll">
        {wait ? (
          <WaitData />
        ) : (
          <div>
            {
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container>
                    <Grid xs={12} sm={12} md={12}>
                      <TextField
                        onChange={({ target: { value } }) => setNombre(value)}
                        value={nombre}
                        label="Nombre"
                        error={getError("nombre", errores) && true}
                        helperText={getError("nombre", errores)}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        onChange={({ target: { value } }) => setCorreo(value)}
                        value={correo}
                        error={getError("correo", errores) && true}
                        helperText={getError("correo", errores)}
                        label="Correo"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        onChange={({ target: { value } }) => setCelular(value)}
                        value={celular}
                        error={getError("celular", errores) && true}
                        helperText={getError("celular", errores)}
                        label="Ingresa tu número de teléfono"
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        onChange={({ target: { value } }) => setMensaje(value)}
                        value={mensaje}
                        label="Mensaje"
                        error={getError("mensaje", errores) && true}
                        helperText={getError("mensaje", errores)}
                        fullWidth={true}
                        multiline
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalContact(false)} color="default">
          Cerrar
        </Button>
        <Button
          disabled={wait}
          onClick={() => callback(setWait)}
          className={classes.btn}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DetailContent({ setModalDetailContent, modalDetailContent, data }) {
  let {
    nombre,
    descripcion,
    tipo_archivo,
    tipo,
    fecha_publicacion,
    archivo,
    id,
  } = data;
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={modalDetailContent}
      onClose={() => setModalDetailContent(false)}
      aria-labelledby="customized-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Button
        className={classes.btnBack}
        onClick={() => setModalDetailContent(false)}
        color="secondary"
        startIcon={<ArrowBackIcon />}
      >
        {" "}
        Volver{" "}
      </Button>
      <DialogTitle
        id="customized-dialog-title"
        className={classes.contentTitle}
      >
        <Hidden xsDown>
          <p className={classes.contentHeader}>{nombre}</p>
        </Hidden>
        <Hidden smUp>
          <p className={classes.contentHeaderApp}>{nombre}</p>
        </Hidden>
      </DialogTitle>
      <DialogContent className="scroll">
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={3} className={classes.containerDetail}>
            {showMedia(classes.contentVideo, tipo_archivo, archivo, false)}
            <Grid item md={12} className={classes.containerTitle}>
              <p className={classes.contenCategory}>
                {tipo ? tipo.nombre : "Ninguna"}
              </p>
              <p className={classes.contentPublished}>
                Publicado : <span>{fecha_publicacion}</span>
              </p>
            </Grid>
            <Grid item md={12} className={classes.containerDescription}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.containerTitleDescription}
              >
                {descripcion}
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.containerShare}>
              <Button
                onClick={() => shareFacebook(nombre, id)}
                className={classes.btnFacebook}
                variant="contained"
                color="secondary"
                endIcon={<FacebookIcon />}
              >
                {" "}
                Compártelo{" "}
              </Button>
              <Button
                onClick={() => shareWhatsApp(id)}
                className={classes.btnWhatsapp}
                variant="contained"
                color="secondary"
                endIcon={<WhatsAppIcon />}
              >
                {" "}
                Compártelo
              </Button>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

function WaitData() {
  const classes = useStyles();
  return (
    <div className={classes.contentWait}>
      <img src={loaderBlack} alt="" className={classes.contentWaitImg} />
    </div>
  );
}

function Wait() {
  return (
    <div>
      <LinearProgress color="secondary" />
      <div
        style={{ padding: "200px 0 0 0", width: "100%", textAlign: "center" }}
      >
        <img src={loaderBlack} width="200" alt="" />
        <p>Cargando...</p>
      </div>
    </div>
  );
}

function CopyrightMore() {
  return (
    <div>
      <p>
        Estos servicios son prestados por Quicklii. Si requiere ayuda, soporte
        técnico o más información comuníquese en Colombia en las líneas de
        atención al cliente:
      </p>
      <ul>
        <li>018000180532</li>
        <li>customer.support@quicklii.co</li>
      </ul>
      <p>Todos los derechos reservados {new Date().getFullYear()}.</p>
    </div>
  );
}

export default function Template39({ dataService }) {
  const classes = useStyles();
  const [activeContent, setActiveContent] = useState("");
  const [waitPin, setWaitPin] = useState(false);
  const [pinSend, setPintSend] = useState(false);
  const [modalPin, setModalPin] = useState(false);
  const [pin, setPin] = useState("");
  const [accion, setAccion] = useState({});
  const params = useParams();
  const [wait, setWait] = useState(true);
  const [waitContent, setWaitContent] = useState(true);
  const [error, setError] = useState(false);
  const [errorContent, setErrorContent] = useState(false);
  const [tipoPF, setTipoPF] = useState(0);
  const [pk, setPk] = useState(params.id);
  const [service, setService] = useState({});
  const [dataContent, setDataContent] = useState({});
  const [categorys, setCategorys] = useState([]);
  const [content, setContent] = useState([]);
  const [modalTerms, setModalTerms] = useState(false);
  const [modalPoliticas, setModalPoliticas] = useState(false);
  const [modalDetailContent, setModalDetailContent] = useState(false);
  const [modalCopyrigth, setModalCopyrigth] = useState(false);
  const [modalQuestion, setModalQuestion] = useState(false);
  const [modalContact, setModalContact] = useState(false);
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [celular, setCelular] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [errores, setErrores] = useState([]);
  const [publicity, setPublicity] = useState([]);

  useEffect(() => {
    getData(pk);
  }, []);

  const getData = async (pk) => {
    setWait(true);
    let { success, data: dataC } = await getCategorys(pk);
    if (success) {
      setService(dataService);
      setCategorys(dataC);
      getDataContent([], dataService);
    } else setError(true);
    setWait(false);
  };

  const sendPinPhone = async () => {
    setWaitPin(true);
    consulta(
      `contenido/pin`,
      { servicioId: pk, celular },
      "post",
      (error, estado, resp) => {
        if (estado == 200) {
          setPintSend(true);
          Toast.fire({ icon: "success", title: resp.titulo });
        } else {
          setPintSend(false);
          Toast.fire({
            icon: "info",
            title: resp.titulo ? resp.titulo : mostrarError(resp),
          });
        }
        console.log(resp);
      },
      false
    );
    setWaitPin(false);
  };

  const confirmPinPhone = async () => {
    setWaitPin(true);
    consulta(
      `contenido/confirmar`,
      { servicioId: pk, celular, codigo: pin },
      "post",
      (error, estado, resp) => {
        if (estado == 200) {
          Toast.fire({ icon: "success", title: resp.titulo });
          setModalPin(false);
          setPin("");
          setCelular("");
          setActiveContent(celular);
          getDataContent([{ llave: "activate", valor: celular }], service);
        } else {
          Toast.fire({
            icon: "info",
            title: resp.titulo ? resp.titulo : mostrarError(resp),
          });
        }
      },
      false
    );
    setWaitPin(false);
  };

  const getDataContent = async (filters = [], serv = null) => {
    serv = !serv ? service : serv;
    let deactivePublicity = filters.find(
      (element) => element.llave == "searching"
    );
    let activate = filters.find((element) => element.llave == "activate");
    setWaitContent(true);
    let { uId, contentPk, tel } = await getPermissions();
    if (tel) filters.push({ llave: "tel", valor: tel });
    if (filters.length == 0 && contentPk)
      filters.push({ llave: "id", valor: contentPk });
    filters.push({ llave: "uId", valor: uId });
    filters.push({ llave: "servicio", valor: pk });
    if (!activate && activeContent.length > 0)
      filters.push({ llave: "activate", valor: activeContent });
    let {
      success: successCon,
      data: dataContenido,
      category,
      accion,
    } = await getContentService(filters);
    if (accion && (accion.accion == "pin" || accion.accion == "info"))
      setModalPin(true);
    setAccion(accion);
    let { data: dataPublicity, success: successPublicity } = await getPublicity(
      pk
    );
    if (successCon) {
      //Set the data in this line code
      await addPublicity(
        dataContenido,
        dataPublicity,
        successPublicity,
        deactivePublicity
      );
      await addSuscription(dataContenido, serv, category);
      setTipoPF(category);
    } else setErrorContent(true);
    setWaitContent(false);
  };

  const addNotification = async (data, callback) => {
    let errores = await validateInputs([
      { value: nombre, name: "nombre", err: { empty: true } },
      { value: correo, name: "correo", err: { empty: true } },
      { value: celular, name: "celular", err: { empty: true } },
      { value: mensaje, name: "mensaje", err: { empty: true } },
    ]);
    if (errores.length == 0) {
      setErrores([]);
      callback(true);
      data = await crear_form_data(data);
      formulario(
        "notificacion/crear",
        data,
        "post",
        (error, estado, resp) => {
          if (estado === 200) {
            setNombre("");
            setMensaje("");
            setCorreo("");
            setCelular("");
            setModalContact(false);
            callback(false);
            Toast.fire({ icon: "success", title: resp.titulo });
          } else {
            Toast.fire({
              icon: "info",
              title: resp.titulo ? resp.titulo : mostrarError(resp),
            });
            callback(false);
          }
        },
        false
      );
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  function TransformQuestion({ text }) {
    let list = text.search("!");
    let listF = text.search("¡");
    if (list != -1 && listF != -1) {
      let preguntas = text.split("!");
      return preguntas.map((e, i) => {
        let p = e.split("¡");
        if (p.length == 1) return "";
        return (
          <List key={i}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={``}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {`${p[0]}`}
                    </Typography>
                    <p className={classes.textModal}>{p[1]}</p>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        );
      });
    } else return text;
  }

  function addPublicity(
    content,
    dataPublicity,
    succesPublicity,
    deactivePublicity
  ) {
    let index = 0;
    return new Promise((resolve) => {
      if (!deactivePublicity && succesPublicity) {
        dataPublicity.map(({ principal: { archivo, valorc } }) => {
          let data = { archivo, valorc, tipo: "publicidad" };
          content.splice(index, 0, data);
          if (index < dataPublicity.length) index = index + 2;
        });
      }
      setContent(content);
      setPublicity(dataPublicity);
      resolve(true);
    });
  }

  function addSuscription(content, service, category) {
    const { valord, valorr } = service;
    let indice = 2;
    let name =
      service && service.nombre ? service.nombre.replace(/ /g, "") : "portal";
    return new Promise((resolve) => {
      if (category == 1) {
        resolve(content);
      } else {
        let n = Math.ceil(content.length / 5);
        n = n == 0 ? 1 : n;
        for (let index = 0; index < n; index++) {
          let data = {
            nombre:
              valorr == "ZED" || valorr == "ZED_DIGITEL"
                ? "Accede ahora mismo al contenido."
                : "Suscríbete ahora mismo! Y accede a todo el contenido.",
            link:
              valorr == "ZED_DIGITEL"
                ? valord
                : `/suscripcion/${name}/${service.id}`,
            tipo: "suscripcion",
          };
          content.splice(indice, 0, data);
          indice = indice + 5;
        }
        resolve(content);
      }
    });
  }

  function TransformText({ text }) {
    let list = text.search("!");
    let listF = text.search("¡");
    if (list != -1 && listF != -1) {
      let resp = text.split("¡");
      return resp.map((e, i) => {
        let p = e.split("!");
        if (p.length == 1) return "";
        return (
          <List key={i}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={``}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {`${p[0]}`}
                    </Typography>
                    <p className={classes.textModal}>{p[1]}</p>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        );
      });
    } else return text;
  }

  if (wait) return <Wait />;
  else if ((!wait && error) || (!waitContent && errorContent))
    return <NotFound />;
  else
    return (
      <div
        className={classes.mainPanel}
        style={{
          backgroundImage: service.archivo5 && `url(${API}${service.archivo5})`,
        }}
      >
        {<SetTitle service={service} />}
        <Hidden xsDown>
          <Header service={service} />
        </Hidden>
        <MenuDesktop
          categorys={categorys}
          getDataContent={getDataContent}
          service={service}
        />
        <div className={classes.mainPanelContent}>
          {waitContent ? (
            <WaitData />
          ) : (
            <Grid container justify="center" alignItems="center">
              {content.length == 0 && (
                <Empty menssage={`No se encontro contenido.`} />
              )}
              {content.map((e, i) => {
                if (e.tipo == "publicidad") {
                  return (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      key={i}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={3}
                    >
                      <Publicity data={e} />
                    </Grid>
                  );
                } else if (e.tipo == "suscripcion") {
                  return (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      key={i}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={3}
                    >
                      <Suscription data={e} />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      key={i}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={3}
                      onClick={() => {
                        setDataContent(e);
                        setModalDetailContent(true);
                      }}
                    >
                      <ShowContentCard
                        image={e.archivo2}
                        category={e.nombre}
                        date={e.fecha_publicacion}
                        description={e.descripcion}
                      />
                    </Grid>
                  );
                }
              })}
            </Grid>
          )}
        </div>
        <Footer
          service={service}
          setModalQuestion={setModalQuestion}
          setModalTerms={setModalTerms}
          setModalPoliticas={setModalPoliticas}
          setModalCopyrigth={setModalCopyrigth}
        />
        <ModalText
          title="Términos y Condiciones"
          modalText={modalTerms}
          setModalTexto={setModalTerms}
          text={
            service && service.valore ? (
              <TransformText text={service.valore} />
            ) : (
              ""
            )
          }
        />
        <ModalText
          title="Políticas de privacidad"
          modalText={modalPoliticas}
          setModalTexto={setModalPoliticas}
          text={
            service && service.valorg ? (
              <TransformText text={service.valorg} />
            ) : (
              ""
            )
          }
        />
        <ModalText
          title="Copyright© Quicklii 2021"
          modalText={modalCopyrigth}
          setModalTexto={setModalCopyrigth}
          text={<CopyrightMore />}
        />
        <ModalText
          title="Preguntas Fecuentes"
          modalText={modalQuestion}
          setModalTexto={setModalQuestion}
          text={
            service && service.valori ? (
              <TransformQuestion text={service.valori} />
            ) : (
              ""
            )
          }
        />
        <DetailContent
          setModalDetailContent={setModalDetailContent}
          modalDetailContent={modalDetailContent}
          data={dataContent}
        />
        <Contact
          callback={(setWait) =>
            addNotification(
              {
                nombre,
                mensaje,
                correo,
                celular,
                asunto: `NOTIFICACIÓN - ${service.nombre}`,
              },
              setWait
            )
          }
          errores={errores}
          setModalContact={setModalContact}
          modalContact={modalContact}
          setNombre={setNombre}
          nombre={nombre}
          setCorreo={setCorreo}
          correo={correo}
          setCelular={setCelular}
          celular={celular}
          setMensaje={setMensaje}
          mensaje={mensaje}
        />
        <Fab
          elevation={3}
          onClick={() => setModalContact(true)}
          className={classes.btnEmail}
          color="default"
          aria-label="edit"
        >
          {" "}
          <EmailIcon />{" "}
        </Fab>
        {service.valorr == "ZED" && (
          <ModalPin
            waitPin={waitPin}
            pinSend={pinSend}
            modalPin={modalPin}
            setModalPin={setModalPin}
            accion={accion}
            pin={pin}
            setPin={setPin}
            celular={celular}
            setCelular={setCelular}
            errores={errores}
            callback={() => sendPinPhone()}
            callback2={() => confirmPinPhone()}
          />
        )}
      </div>
    );
}
