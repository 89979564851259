
import React, { useState, useEffect } from 'react';
//Funtions
import { consulta } from '../funciones/general';



export default function Headers() {
  const [wait, setWait] = useState(true);
  const [data, setData] = useState({});


  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    return new Promise((resolve) => {
      consulta(`headers`, null, null, (error, estado, resp) => {
        console.log(resp);
        setData(resp.data);
        setWait(false);
        resolve(true);
      }, false);
    });
  };

  const mostrar = () => {
    let resp = "";
    for (var clave in data) {
      // Controlando que data realmente tenga esa propiedad
      if (data.hasOwnProperty(clave)) {
        // Mostrando en pantalla la clave junto a su valor
        resp = <div>{resp}{clave + " : " + data[clave]} <br /></div>;
      }
    }
    return resp;
  }

  if (wait) return "cargando...";
  else return mostrar();

}
