import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
  blackColor,
} from "../../material-dashboard-react.js";

const dashboardStyle = {
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    textAlign: 'right',
    color: grayColor[0],
    // display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  statsIcon: {
    padding: "0 5px 7px 5px",
    color: 'white',
  },
  statsTolltip: {
    backgroundColor: 'white',
    borderRadius: "0 0 5px 5px",
    marginRight: 8,
    borderTop: "1px solid #E9E7E7",
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(blackColor) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(whiteColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)"
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0"
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    // textAlign: 'justify',
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardItem: {
    margin: "10px 0 0 0",
    cursor: "pointer",
    height: '200px',
    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: '#f5f5f5',
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(whiteColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    },
  },
  tableRow: {
    margin: "10px 0 10px 0",
    padding: "5px 0 5px 0",
    borderBottom: "1px solid #E3E3E3"
  },
  tableRowTitle: {
    fontSize: '17px',
    color: '#7986cb',
    margin: "0",
    textAling: 'left',
  },
  tableRowVal: {
    textAling: 'left',
    fontSize: '15px',
    margin: "0",
  },
  rootList: {
    width: '100%',
    padding: 0,
    margin: 0,
    backgroundColor: 'white',
  },
  itemList: {
    // padding: 0,
    // margin: 0,
  },
  formControl: {
    width: '100%',
  },

};

export default dashboardStyle;
