import DashboardPage from "./views/Dashboard.js";
import Users from "./views/Users.js";
import Login from "./views/Login.js";
import Category from "./views/Category.js";
import Publicity from "./views/Publicity.js";
import Trivias from "./views/Trivias.js";
import Template1 from "./views/Template1.js";
import Template2 from "./views/Template2.js";
import Template3 from "./views/Template3.js";
import Template4 from "./views/Template4.js";
import Template5 from "./views/Template5.js";
import Template6 from "./views/Template6.js";
import Template7 from "./views/Template7.js";
import Template8 from "./views/Template8.js";
import Template9 from "./views/Template9.js";
import Template10 from "./views/Template10.js";
import Template11 from "./views/Template11.js";
import Template12 from "./views/Template12.js";
import Template13 from "./views/Template13.js";
import Template14 from "./views/Template14.js";
import Template15 from "./views/Template15.js";
import Template16 from "./views/Template16.js";
import Headers from "./views/Headers.js";
import Inscripcion from "./views/Inscripcion.js";
import Dashboard from "@material-ui/icons/Dashboard";

const dashboardRoutes = [
  {
    path: "/",
    name: "Servicios",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/",
    auth: true,
  },
  {
    path: "/servicios",
    name: "Servicios",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    auth: true,
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: Dashboard,
    component: Users,
    layout: "/admin",
    auth: true,
  },
  {
    path: "/ingresar",
    name: "Ingresar",
    icon: Dashboard,
    component: Login,
    layout: "/admin",
    auth: false,
  },
  {
    path: "/categorias",
    name: "Categorias",
    icon: Dashboard,
    component: Category,
    layout: "/admin",
    auth: true,
  },
  {
    path: "/publicidad",
    name: "Publicidad",
    icon: Dashboard,
    component: Publicity,
    layout: "/admin",
    auth: true,
  },
  {
    path: "/trivias",
    name: "trivias",
    icon: Dashboard,
    component: Trivias,
    layout: "/admin",
    auth: true,
  },
  {
    path: "/tmp1",
    name: "Template 1",
    icon: Dashboard,
    component: Template1,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp2",
    name: "Template 2",
    icon: Dashboard,
    component: Template2,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp3",
    name: "Template 3",
    icon: Dashboard,
    component: Template3,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp4",
    name: "Template 4",
    icon: Dashboard,
    component: Template4,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp5",
    name: "Template 5",
    icon: Dashboard,
    component: Template5,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp6",
    name: "Template 6",
    icon: Dashboard,
    component: Template6,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp7",
    name: "Template 7",
    icon: Dashboard,
    component: Template7,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp8",
    name: "Template 8",
    icon: Dashboard,
    component: Template8,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp9",
    name: "Template 9",
    icon: Dashboard,
    component: Template9,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp10",
    name: "Template 10",
    icon: Dashboard,
    component: Template10,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp11",
    name: "Template 11",
    icon: Dashboard,
    component: Template11,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp12",
    name: "Template 12",
    icon: Dashboard,
    component: Template12,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp13",
    name: "Template 13",
    icon: Dashboard,
    component: Template13,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp14",
    name: "Template 14",
    icon: Dashboard,
    component: Template14,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp15",
    name: "Template 15",
    icon: Dashboard,
    component: Template15,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/tmp16",
    name: "Template 16",
    icon: Dashboard,
    component: Template16,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/headers",
    name: "Headers",
    icon: Dashboard,
    component: Headers,
    layout: "/templates",
    auth: false,
  },
  {
    path: "/suscripcion",
    name: "suscripcion",
    icon: Dashboard,
    component: Inscripcion,
    layout: "/templates",
    auth: false,
  },
];

export default dashboardRoutes;
